var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.titulo))])]),_c('div',{staticClass:"col-sm-2",staticStyle:{"text-align":"right"}},[_c('small',[_vm._v(_vm._s(_vm.loadingMessage)+" ")])]),_c('div',{staticClass:"col-sm-1"},[_c('pulse-loader',{attrs:{"loading":_vm.loading,"color":"black","size":"5px"}})],1)]),_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-4"},[_c('ValidationProvider',{attrs:{"name":"Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.relatorio)?_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Data")]):_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Período da Data")]),(!_vm.relatorio)?_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","format":"dd/MM/yyyy","value-format":"yyyy-MM-dd","placeholder":_vm.relatorio ? 'Não filtrar' : 'Selecione data'},model:{value:(_vm.model.data),callback:function ($$v) {_vm.$set(_vm.model, "data", $$v)},expression:"model.data"}}):_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"daterange","range-separator":" à ","format":"dd/MM/yyyy","value-format":"yyyy-MM-dd","start-placeholder":"Data inicial","end-placeholder":"Data final"},model:{value:(_vm.model.data_date_range),callback:function ($$v) {_vm.$set(_vm.model, "data_date_range", $$v)},expression:"model.data_date_range"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Aluno","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Aluno")]),_c('el-select',{staticClass:"baseSelect",staticStyle:{"width":"100%"},attrs:{"filterable":"","size":"large","placeholder":_vm.relatorio ? 'Não filtrar' : 'Aluno'},model:{value:(_vm.model.aluno_id),callback:function ($$v) {_vm.$set(_vm.model, "aluno_id", $$v)},expression:"model.aluno_id"}},[(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não filtrar","value":""}}):_vm._e(),_vm._l((_vm.selects.alunos),function(item){return _c('el-option',{key:item.id,staticClass:"select-danger",staticStyle:{"width":"100%"},attrs:{"value":item.id,"label":item.user.name}})})],2),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-2"},[_c('ValidationProvider',{attrs:{"name":"Peso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Peso',"label":"Peso"},model:{value:(_vm.model.peso),callback:function ($$v) {_vm.$set(_vm.model, "peso", $$v)},expression:"model.peso"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Ombro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Ombro',"label":"Ombro"},model:{value:(_vm.model.ombro),callback:function ($$v) {_vm.$set(_vm.model, "ombro", $$v)},expression:"model.ombro"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Peitoral","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Peitoral',"label":"Peitoral"},model:{value:(_vm.model.peitoral),callback:function ($$v) {_vm.$set(_vm.model, "peitoral", $$v)},expression:"model.peitoral"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Braço Direito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Braço Direito',"label":"Braço Direito"},model:{value:(_vm.model.braco_direito),callback:function ($$v) {_vm.$set(_vm.model, "braco_direito", $$v)},expression:"model.braco_direito"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Antebraço Direito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Antebraço Direito',"label":"Antebraço Direito"},model:{value:(_vm.model.antebraco_direito),callback:function ($$v) {_vm.$set(_vm.model, "antebraco_direito", $$v)},expression:"model.antebraco_direito"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Braço Esquerdo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Braço Esquerdo',"label":"Braço Esquerdo"},model:{value:(_vm.model.braco_esquerdo),callback:function ($$v) {_vm.$set(_vm.model, "braco_esquerdo", $$v)},expression:"model.braco_esquerdo"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Antebraço Esquerdo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Antebraço Esquerdo',"label":"Antebraço Esquerdo"},model:{value:(_vm.model.antebraco_esquerdo),callback:function ($$v) {_vm.$set(_vm.model, "antebraco_esquerdo", $$v)},expression:"model.antebraco_esquerdo"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-4"},[_c('ValidationProvider',{attrs:{"name":"Cintura","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Cintura',"label":"Cintura"},model:{value:(_vm.model.cintura),callback:function ($$v) {_vm.$set(_vm.model, "cintura", $$v)},expression:"model.cintura"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-4"},[_c('ValidationProvider',{attrs:{"name":"Abdômen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Abdômen',"label":"Abdômen"},model:{value:(_vm.model.abdomen),callback:function ($$v) {_vm.$set(_vm.model, "abdomen", $$v)},expression:"model.abdomen"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-4"},[_c('ValidationProvider',{attrs:{"name":"Quadril","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Quadril',"label":"Quadril"},model:{value:(_vm.model.quadril),callback:function ($$v) {_vm.$set(_vm.model, "quadril", $$v)},expression:"model.quadril"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Coxa Direita","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Coxa Direita',"label":"Coxa Direita"},model:{value:(_vm.model.coxa_direita),callback:function ($$v) {_vm.$set(_vm.model, "coxa_direita", $$v)},expression:"model.coxa_direita"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Perna Direita","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Perna Direita',"label":"Perna Direita"},model:{value:(_vm.model.perna_direita),callback:function ($$v) {_vm.$set(_vm.model, "perna_direita", $$v)},expression:"model.perna_direita"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Coxa Esquerda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Coxa Esquerda',"label":"Coxa Esquerda"},model:{value:(_vm.model.coxa_esquerda),callback:function ($$v) {_vm.$set(_vm.model, "coxa_esquerda", $$v)},expression:"model.coxa_esquerda"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Perna Esquerda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Perna Esquerda',"label":"Perna Esquerda"},model:{value:(_vm.model.perna_esquerda),callback:function ($$v) {_vm.$set(_vm.model, "perna_esquerda", $$v)},expression:"model.perna_esquerda"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('br'),_c('router-link',{attrs:{"to":_vm.goBack}},[_c('p-button',{attrs:{"type":"info","round":""}},[_vm._v("voltar")])],1),(_vm.relatorio)?_c('p-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"danger","round":""},on:{"click":function($event){return _vm.getReport('pdf')}}},[_vm._v("PDF")]):_vm._e(),(_vm.relatorio)?_c('p-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"success","round":""},on:{"click":function($event){return _vm.getReport('xlsx')}}},[_vm._v("XLSX")]):_c('p-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"success","round":"","nativeType":"submit"}},[_vm._v(_vm._s(_vm.labelSubmit))])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }