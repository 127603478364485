<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <h3 slot="header" class="header text-center">Seja bem-vindo!</h3>

                  <fg-input v-model="form.username" addon-left-icon="nc-icon nc-single-02"
                            placeholder="E-mail"></fg-input>

                  <fg-input v-model="form.password" addon-left-icon="nc-icon nc-key-25" placeholder="Senha"
                            type="password"></fg-input>

                  <br>

                  <p-button native-type="submit"  slot="footer" type="warning" round block class="mb-3">entrar</p-button>
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" :style="setBackground(image)"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import laravueNotify from '@/components/ProjetoBase/Views/Components/notifications/laravueNotifications'
  import { Card, Checkbox, Button } from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    props: {
        image: {
          type: String,
          default: require("@/assets/img/background-adm.jpg")
        }
    },
    data() {
      return {
        form: {
          username: '',
          password: ''
        }
      }
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      login() {
        this.$http.post(`login`, {
          email: this.form.username,
          password: this.form.password
        }).then(response => {
          let user = response.data.data

          user.id = this.CryptoJS.AES.encrypt(JSON.stringify(user.id), this.$secretKey).toString()
          user.avatar_id = this.CryptoJS.AES.encrypt(JSON.stringify(user.avatar_id), this.$secretKey).toString()

          // Keys config
          let access_keys = user.access_keys
          let keys = []
          for (var i = 0; i < access_keys.length; i++){
            keys.push(this.CryptoJS.AES.encrypt(access_keys[i].name, this.$secretKey).toString())
          }
          user.keys = keys

          // Avatar config
          if( !user.avatar ) {
            user.avatar = '/static/img/faces/face-0.jpg'
          }

          // Cleaning properties
          delete user.access_keys
          delete user.permissions
          delete user.roles

          // Storing user
          localStorage.setItem('user', JSON.stringify(user))
          localStorage.setItem('attempts', 0)
          
          this.$router.push( { path: '/'} )
        })
        .catch(e => {
          laravueNotify.failure(this, e)
        })
      },
      setBackground(image){
        return {
          backgroundImage: `url(${image})`
        }
      }
    }
  }
</script>
<style>
</style>