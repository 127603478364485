<template>
  <div class="row">
    <div class="col-lg-5 col-md-6">
      <user-card></user-card>
      <!-- <permissions-card></permissions-card> -->
    </div>
    <div class="col-lg-7 col-md-6">
      <edit-profile-form></edit-profile-form>
    </div>
  </div>
</template>
<script>
  import EditProfileForm from './UserProfile/EditProfileForm.vue'
  import UserCard from './UserProfile/UserCard.vue'
  import PermissionsCard from './UserProfile/PermissionsCard.vue'

  export default {
    components: {
      EditProfileForm,
      UserCard,
      PermissionsCard
    }
  }

</script>
<style>

</style>
