<template>
  <div>
    <permission-datatable
      rota="permissions"
      :colunas = "colunas"
      :ordenacao = "ordenacao">
    </permission-datatable>
  </div>
</template>

<script>
import PermissionDatatable from "./components/PermissionDataTable";

export default {
  components: { PermissionDatatable },
  data() {
    return {
      colunas: [
        {
          prop: "name",
          label: "Nome",
          minWidth: 290,
        },
      ],
      ordenacao:[
        {
          prop: "name",
          order: "ASC",
          default: true
        }
      ]
    }
  }
}
</script>
