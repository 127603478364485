<template>
  <div>
    <h4 slot="header" class="card-title" style="margin-bottom:32px">Ferramentas</h4>

    <el-tabs v-model="activeTab" type="border-card">
    <el-tab-pane v-if="$can('ver tasks')" name="task">
      <span slot="label"><i class="el-icon-copy-document"></i> Tarefas</span>
      <tarefa></tarefa>
    </el-tab-pane>
    <el-tab-pane v-if="$can('ver monitors')" name="monitor">
        <span slot="label"><i class="el-icon-monitor"></i> Monitoramento</span>
        <monitor></monitor>
    </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
import Monitor from 'src/components/ProjetoBase/Views/Pages/Monitor/Configuration.vue'
import Tarefa from 'src/components/ProjetoBase/Views/Pages/Task/Configuration.vue'

export default {
    components: { Monitor, Tarefa },
    data() {
      return {
        activeTab: String
      }
    },
    created() {
      this.activeTab = this.$route.params.tab
      console.log('tab', this.activeTab)
    }
}
</script>

<style>

</style>