<template>
    <div>
        <div class="wrapper wrapper-full-page">
            <div class="full-page login-page section-image">
                <div class="content">
                    <div class="container">
                        <div class="col-lg-4 col-md-6 ml-auto mr-auto" align="center">
                            <div @click="showError()" align="center"> 
                                <info-section class="mt-5"
                                        type="warning"
                                        title="No Auth"
                                        align="center"
                                        description="Ocorreu um problema de acesso ao servidor."
                                        icon="nc-icon nc-alert-circle-i">
                                    <h2 class="text-white mt-3"  align="center"> 
                                        <!--  {{ this.$route.params.error }}  -->
                                        Sem conexão com servidor
                                    </h2>
                                </info-section>
                            </div>
                            <small  class="text-white"  align="center">Ocorreu um problema durante a conexão ao servidor.</small> 
                            <br /> <br /> <br /> <br /> 
                            <h5 class="text-white mt-1"  align="center"> <a  align="center" href="http://treinamentomt.com.br">Treinamento MT</a> </h5>     
                        </div>
                    </div>
                </div>
                <div class="full-page-background" :style="sectionImage(background)">
                </div>
            </div>
        </div>
        <!-- Classic Modal -->
        <modal :show.sync="showModal" headerClasses="justify-content-center">
            <h4 slot="header" class="title title-up">
                <i class="nc-icon nc-alert-circle-i"></i>
                <p style="color:red">Erro de Conexão</p>
            </h4>
            <p id="errorLog"></p>
            <template slot="footer">
                <div class="right-side">
                    <p-button type="danger" link @click="modalCandel">Cancelar</p-button>
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
    import { Modal, InfoSection } from "src/components/UIComponents";
    export default {
        components: {
            Modal,
            InfoSection
        },
        props: {
            background: {
                type: String,
                default: require("@/assets/img/background/jan-sendereks.jpg")
            }
        },
        data() {
            return {
                showModal: false,
            };
        },  
        methods: {
            showError () {
                var pError = this.$route.params.error
                document.getElementById("errorLog").innerHTML = '';
                window.console = {
                    log: function(str){
                        var node = document.createElement("div");
                        node.appendChild(document.createTextNode(str));
                        document.getElementById("errorLog").appendChild(node);
                    }
                }
                console.log(pError);
                console.log('-----------------------------------------------------------------------------');
                if (pError.response) { // Request made and server responded
                    console.log(pError.response.status);
                    console.log(pError.response.data.message);
                } else if (pError.request) { // The request was made but no response was received
                    console.log(pError.request.responseText);
                } else { // Something happened in setting up the request that triggered an Error
                    console.log(pError.message);
                }
                console.log('-----------------------------------------------------------------------------');
                let strError = JSON.stringify(pError);
                console.log(strError);
                this.showModal = true;
            },
            modalCandel() {
                this.showModal = false;
            },
            sectionImage(image) {
            return {
                backgroundImage: `url(${image})`
            }
      }
        },
        created() {
            console.log(this.$route.params.error)
            if ( ! this.$route.params.error) {
                this.$route.params.error = '';
            }  
        }    
    }
</script>
<style>
</style>