import NotificationAddSuccessTemplate from "@/components/ProjetoBase/Views/Components/notifications/LaravueNotificationAddSuccessTemplate"
import NotificationEditSuccessTemplate from "@/components/ProjetoBase/Views/Components/notifications/LaravueNotificationEditSuccessTemplate"
export default {
    message(pThis, message, type = 'success') {
        pThis.$notify({
            type: type,
            icon: "nc-icon nc-simple-remove",
            message: message,
            title: 'Atenção',
            horizontalAlign: "right",
            verticalAlign: "top",
            timeout: 10000,
          })
    },
    success(pThis, isAdd = 'add') {
        pThis.$notify({
            component: isAdd == 'add' ? NotificationAddSuccessTemplate : NotificationEditSuccessTemplate,
            icon: "nc-icon nc-check-2",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success"
          })
    },
    failure(pThis, e) {

        let listMessage = new Array()
        let typeMessage = 'warning'
        let titleMessage = 'Atenção'
        let timeoutMessage = 10000

        if (e.response) { // Servidor respondeu a requisição
        
            if (e.response.status === 401) { // Servidor respondeu a requisição de que o usuário não está autenticado
                let attempts = parseInt( localStorage.getItem('attempts') )
                if( attempts++ > 3 ) {
                    localStorage.clear()
                }
                localStorage.setItem( 'attempts', attempts )
                location.href = '/'   
            } else {
                if (e.response.status >= 500) {
                    typeMessage = 'danger'
                }
                if (typeof e.response.data.message === 'string') {
                    listMessage.push(e.response.data.message)
                } else if (Array.isArray(e.response.data.message)) {
                    listMessage = e.response.data.message
                } else {
                    if (e.response.data.message) {
                      listMessage.push('Erro: ' + e.response.data.message)
                    } else { // Caso a resposta de erro venha em formado arraybuffer (responseType: 'arraybuffer')
                        var decodedString = String.fromCharCode.apply(null, new Uint8Array(e.response.data))
                        var obj = JSON.parse(decodedString)
                        listMessage.push(obj['message'])
                    }
                }
            } 
        } else  { // Algum erro na configuração da requisição ou não recebeu resposta do servidor
            if (e.request) { // Não foi recebida nenhuma resposta do servidor para requisição
                listMessage.push(e.request.responseText)
            } 
            listMessage.push(e.message)
            typeMessage = 'danger'
        }

        listMessage.forEach(element => {
            pThis.$notify({
                type: typeMessage,
                icon: "nc-icon nc-simple-remove",
                message: element,
                title: titleMessage,
                horizontalAlign: "right",
                verticalAlign: "top",
                timeout: timeoutMessage,
            })
        })
    }
}