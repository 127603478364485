<template>

    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <info-section class="mt-5"
                            type="danger"
                            title="Marketing"
                            description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                            icon="nc-icon nc-alert-circle-i">
                <h2 class="text-white mt-3">Sessão encerrada.</h2>
                <div class="text-white">
                  Você pode renovar o <a href="/login">acesso novamente</a>.
                </div>
              </info-section>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" :style="sectionImage(background)">
        </div>
      </div>
    </div>

</template>
<script>
  import {InfoSection} from 'src/components/UIComponents';
  import AppFooter from './Layout/AppFooter'
  export default {
    components: {
      AppFooter,
      InfoSection,
    },
    props: {
      background: {
      type: String,
      default: require("@/assets/img/background/jan-sendereks.jpg")
    }
  },
    methods: {
      sectionImage(image) {
        return {
          backgroundImage: `url(${image})`
        }
      }
    }
  }
</script>
<style>
</style>
