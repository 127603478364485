<template>
  <div class="row">
    <div class="col-sm-12">
      <card>
       <div class="row">
          <div class="col-sm-9">
              <h4 slot="header" class="card-title">{{ titulo }}</h4>
          </div>
          <div class="col-sm-2" style="text-align:right">
            <small>{{ loadingMessage }} </small>
          </div>
          <div class="col-sm-1">
               <pulse-loader :loading="loading" color="black" size="5px"></pulse-loader>
          </div>
        </div>
        <ValidationObserver ref="form">
            <form @submit.prevent="submit">
              <div class="row formSpace">
                <div class="col-sm-6">
                  <ValidationProvider name="Grupo" rules="required" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Grupo</div>
                      <el-select
                        filterable
                        class="baseSelect"
                        style="width: 100%;"
                        label="Grupo"
                        size="large"
                        :placeholder="relatorio ? 'Não filtrar' : 'Grupo'"
                        v-model="model.task_group_id"
                      >
                        <el-option v-if="relatorio" label="Não filtrar" value=""></el-option>
                        <el-option
                          v-for="item in selects.taskgroups"
                          class="select-danger"
                          style="width: 100%;"
                          :value="item.id"
                          :label="item.name"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Status</div>
                    <el-select
                      filterable
                      class="baseSelect"
                      style="width: 100%;"
                      label="Status"
                      size="large"
                      :placeholder="relatorio ? 'Não filtrar' :'Status'"
                      v-model="model.task_status_id"
                    >
                      <el-option v-if="relatorio" label="Não filtrar" value=""></el-option>
                      <el-option
                        v-for="item in selects.taskstatuses"
                        class="select-danger"
                        :value="item.id"
                        :label="item.name"
                        :key="item.id"
                      ></el-option>
                    </el-select>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row formSpace">
                <div class="col-sm-6">
                  <ValidationProvider name="Módulo" rules="required" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Módulo</div>
                      <el-select
                        filterable
                        class="baseSelect"
                        style="width: 100%;"
                        label="Módulo"
                        size="large"
                        :placeholder="relatorio ? 'Não filtrar' :'Projeto'"
                        v-model="model.project_module_id"
                      >
                        <el-option v-if="relatorio" label="Não filtrar" value=""></el-option>
                        <el-option
                          v-for="item in selects.projectmodules"
                          class="select-danger"
                          :value="item.id"
                          :label="item.name"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider name="Usuário" rules="required" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Usuário</div>
                      <el-select
                        filterable
                        class="baseSelect"
                        style="width: 100%;"
                        label="Usuário"
                        size="large"
                        :placeholder="relatorio ? 'Não filtrar' :'Usuário'"
                        v-model="model.user_id"
                      >
                        <el-option v-if="relatorio" label="Não filtrar" value=""></el-option>
                        <el-option
                          v-for="item in selects.users"
                          class="select-danger"
                          :value="item.id"
                          :label="item.name"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row formSpace">
                <div class="col-sm-12">
                  <ValidationProvider name="Tarefa" rules="required" v-slot="{ errors }">
                    <fg-input 
                      :placeholder="relatorio ? 'Não filtrar' :'Digite Tarefa'" 
                      label="Tarefa" 
                      v-model="model.name">
                    </fg-input>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <ValidationProvider name="Início" rules="" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Início</div>
                    <el-date-picker
                      v-model="model.started_at"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      :placeholder="relatorio ? 'Não filtrar' :'Selecione data'">
                    </el-date-picker>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-3">
                  <ValidationProvider name="Fim" rules="" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Fim</div>
                    <el-date-picker
                      v-model="model.ended_at"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      :placeholder="relatorio ? 'Não filtrar' :'Selecione data'">
                    </el-date-picker>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div v-if="relatorio" class="col-sm-3">
                  <ValidationProvider name="Marco Histórico" rules="required" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Marco Histórico</div>
                    <el-select  v-model="model.is_milestone">
                      <el-option v-if="relatorio" label="Não filtrar" value=""></el-option>
                      <el-option v-if="relatorio" label="Sim" value="true"></el-option>
                      <el-option v-if="relatorio" label="Não" value="false"></el-option>
                    </el-select>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div v-else class="col-sm-3">
                  <ValidationProvider name="Marco Histórico" rules="required" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .9971em;">&nbsp;⠀⠀</div>
                    <p-checkbox v-model="isMilestone">Marco Histórico</p-checkbox>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div v-if="relatorio" class="col-sm-3">
                  <ValidationProvider name="Roteiro" rules="required" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Roteiro</div>
                    <el-select  v-model="model.is_roadmap">
                      <el-option v-if="relatorio" label="Não filtrar" value=""></el-option>
                      <el-option v-if="relatorio" label="Sim" value="true"></el-option>
                      <el-option v-if="relatorio" label="Não" value="false"></el-option>
                    </el-select>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div v-else class="col-sm-3">
                  <ValidationProvider name="Roteiro" rules="required" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .9971em;">&nbsp;⠀⠀</div>
                    <p-checkbox v-model="isRoadmap">Roteiro</p-checkbox>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- {{ laravue-insert:field }} -->
              <br>
              <router-link :to="goBack">
                <p-button type="info" round>voltar</p-button>
              </router-link>
              <p-button style="margin-left:8px" v-if="relatorio" type="success" round @click="getReport('xlsx')">XLS</p-button>
              <p-button style="margin-left:8px" v-if="relatorio" type="danger" round @click="getReportDebug('pdf')">PDF</p-button>
              <p-button style="margin-left:8px" v-else nativeType="submit" type="success" round>{{ labelSubmit }}</p-button>
            </form>
        </ValidationObserver>
      </card>
    </div>
  </div>
</template>

<script>
import laravueNotify from "@/components/ProjetoBase/Views/Components/notifications/laravueNotifications"
import PulseLoader from "vue-spinner/src/PulseLoader"
import { DatePicker } from "element-ui"

export default {
  components: { PulseLoader, [DatePicker.name]: DatePicker },
  props: {
    titulo: String,
    edicao: Boolean,
    relatorio: Boolean,
    rotaVoltar: String,
    rota: String
  },
  data() {
    return {
      loadingMessage: "",
      loading: false,
      selects: {
        taskgroups: [],
        taskstatuses: [],
        users: [],
        projectmodules: [],
        // {{ laravue-insert:dataSelects }}
      },
      model: {
        name: "",
        started_at: "",
        ended_at: "",
        is_milestone: this.relatorio ? "" : false,
        is_roadmap: this.relatorio ? "" : true,
        task_group_id: "",
        task_status_id: "",
        project_module_id: "",
        user_id: "",
        // {{ laravue-insert:dataModel }}
      }
    };
  },
  created() {
    this.loadSelects()
  },
  computed: {
    labelSubmit() {
      return this.edicao ? `Alterar` : `Cadastrar`
    },
    goBack() {
      return `/paginas/${this.rotaVoltar}`
    },
    url() {
      return this.edicao
        ? `${this.rota}/${this.$route.params.modelId}`
        : this.rota;
    },
    isMilestone: {
      get: function() {
        return this.model.is_milestone >= 1 ? true : false
      },
      set: function(newValue) {
        this.model.is_milestone = newValue ? 1 : 0
      }
    },
    isRoadmap: {
      get: function() {
        return this.model.is_roadmap >= 1 ? true : false
      },
      set: function(newValue) {
        this.model.is_roadmap = newValue ? 1 : 0
      }
    },
    // {{ laravue-insert:computed }}
  },
  methods: {
    setLoading(status, message) {
      if(this.loading != status){
        this.loading = status
      }
      this.loadingMessage = message
    },
    getReportFilter() {
      return `?model_filter=${JSON.stringify(this.model)}`
    },
    getReportDebug(modelo) {
      this.$http
        .get(`reports/${this.rota}/${modelo}${this.getReportFilter()}`, {responseType: 'arraybuffer'})
        .then(response => {
          this.$getReport(response, modelo)
        })
        .catch(e => {
          console.log(e)
        })
    },
    getReport(modelo) {
      this.$http
        .get(`reports/${this.rota}/${modelo}${this.getReportFilter()}`, {responseType: 'arraybuffer'})
        .then(response => {
          this.$getReport(response, modelo)
        })
        .catch(e => {
          laravueNotify.failure(this, e)
        })
    },
    loadModel(){
      // {{ laravue-insert:loadModelMethod }}
      if (this.edicao) {
        this.setLoading(true, "Tarefa")
        this.$http
          .get(this.url)
          .then(response => {
            this.setLoading(false, "")
            this.model = response.data.data
            // {{ laravue-insert:loadModelResponse }}
          })
          .catch(e => {
            laravueNotify.failure(this, e)
          });
      } else {
        this.setLoading(false, "")
      }
    },
    loadSelects() {
      this.setLoading(true, "carregando")
      this.loadTaskgroups() // cascade calls
    },
    loadTaskgroups() {
      this.setLoading(true, "Grupos")
      this.$http
        .get(`taskgroups?per_page=-1`)
        .then(response => {
          this.selects.taskgroups = response.data.data.data
          this.loadTaskStatuses()
        })
        .catch(e => {
          laravueNotify.failure(this, e)
      });
    },
    loadTaskStatuses() {
      this.setLoading(true, "Status")
      this.$http
        .get(`taskstatuses?per_page=-1`)
        .then(response => {
          this.selects.taskstatuses = response.data.data.data
          this.loadProjectModules()
        })
        .catch(e => {
          laravueNotify.failure(this, e)
      });
    },
    loadProjectModules() {
      this.setLoading(true, "Módulos")
      this.$http
        .get(`projectmodules?per_page=-1`)
        .then(response => {
          this.selects.projectmodules = response.data.data.data
          this.loadUsers()
        })
        .catch(e => {
          laravueNotify.failure(this, e)
        })
    },
    loadUsers() {
      this.setLoading(true, "Usuários")
      this.$http
        .get(`users?per_page=-1`)
        .then(response => {
          this.selects.users = response.data.data.data
          this.loadModel()
        })
        .catch(e => {
          laravueNotify.failure(this, e)
      })
    },
    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        this.setLoading(true, this.edicao ? "Alterando" : "Inserindo")
        this.$http({
          method: this.edicao ? "put" : "post",
          url: this.url,
          data: {
            task_group_id: parseInt(this.model.task_group_id) ,
            task_status_id: parseInt(this.model.task_status_id),
            user_id: parseInt(this.model.user_id),
            project_module_id: parseInt(this.model.project_module_id),
            name: this.model.name,
            started_at: this.model.started_at ,
            ended_at: this.model.ended_at,
            is_milestone: this.model.is_milestone,
            is_roadmap: this.model.is_roadmap,
            // {{ laravue-insert:submit }}
          }
        })
          .then(response => {
            if (!this.edicao) {
              this.model = {
                name: ""
              }
              this.$refs.form.reset()
            }
            this.setLoading(false, "")
            if (this.edicao) {
              laravueNotify.success(this, "edit")
            } else {
              laravueNotify.success(this)
            }
          })
          .catch(e => {
            this.setLoading(false, "")
            laravueNotify.failure(this, e)
          })
      })
    },
    // {{ laravue-insert:methods }}
  }
}
</script>

<style >
.selectCenter {
  margin-top: 1.4rem;
}
.el-select {
  display: inline;
}
.baseSelect .el-input input {
  border: 1px solid #ddd !important;
  color: #66615b;
}
.formSpace {
  margin-bottom: 8px;
}
</style>
