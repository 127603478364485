<template>
  <div class="row">
    <div class="col-sm-12">
      <card>
        <h4 slot="header" class="card-title">{{ titulo }}</h4>
        <ValidationObserver ref="form">
            <form @submit.prevent="submit">
              <div class="row">
                <div class="col-sm-12">
                  <ValidationProvider name="Perfil de Acesso" rules="required" v-slot="{ errors }">
                    <fg-input placeholder="Digite perfil de acesso" label="Perfil de Acesso" v-model="model.name"></fg-input>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- {{ laravue-insert:field }} -->
              <br>
              <router-link :to="goBack">
                <p-button type="info" round>voltar</p-button>
              </router-link>&nbsp;&nbsp;
              <p-button nativeType="submit" type="success" round>{{ labelSubmit }}</p-button>
              <pulse-loader :loading="loading" color="teal" size="15px"></pulse-loader>
            </form>
        </ValidationObserver>
      </card>
    </div>
  </div>
</template>

<script>
import laravueNotify from "@/components/ProjetoBase/Views/Components/notifications/laravueNotifications"
import PulseLoader from "vue-spinner/src/PulseLoader"

export default {
  props: {
    titulo: String,
    edicao: Boolean,
    rotaVoltar: String,
    rota: String
  },
  data() {
    return {
      loading: false,
      selects: { 
        // {{ laravue-insert:dataSelects }}
      },
      model: {
        name: "",
        // {{ laravue-insert:dataModel }}
      },
    }
  },
  created() {
    // {{ laravue-insert:loadModelMethod }}
    // Se edição, preencher o formulário
    if (this.edicao) {
      this.$http
        .get(this.url)
        .then(response => {
          this.model = response.data.data
          // {{ laravue-insert:loadModelResponse }}
        })
        .catch(e => {
          laravueNotify.failure(this, e)
        })
    }
  },
  components: { PulseLoader },
  computed: {
    labelSubmit() {
      return this.edicao ? `Alterar` : `Cadastrar`
    },
    goBack() {
      return `/paginas/${this.rotaVoltar}`
    },
    url() {
      return this.edicao
        ? `${this.rota}/${this.$route.params.modelId}`
        : this.rota
    },
    // {{ laravue-insert:computed }}
  },
  methods: {
    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        this.loading = true;
        this.$http({
          method: this.edicao ? "put" : "post",
          url: this.url,
          data: {
            name: this.model.name,
            // {{ laravue-insert:submit }}
          }
        })
          .then(response => {
            if (!this.edicao) {
              this.model = {
                name: ""
              }
              this.$refs.form.reset()
            }
            this.loading = false
            if (this.edicao) {
              laravueNotify.success(this, "edit")
            } else {
              laravueNotify.success(this)
            }
          })
          .catch(e => {
            this.loading = false;
            laravueNotify.failure(this, e)
          })
      })
    },
    // {{ laravue-insert:methods }}
  }
};
</script>
