<template>
  <div>
    <laravue-datatable
      rota="users"
      :colunas = "colunas">
    </laravue-datatable>
  </div>
</template>

<script>
import LaravueDatatable from "@/components/ProjetoBase/Views/Components/LaravueDataTable"

export default {
  name: "Index.vue",
  components: { LaravueDatatable },
  data() {
    return {
      colunas: [
        {
          prop: "name",
          label: "Nome",
          minWidth: 240,
          showDelete: true
        },
        {
          prop: "email",
          label: "E-mail",
          minWidth: 150,
          showDelete: false
        }
      ]
    }
  }
}
</script>
