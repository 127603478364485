export default {
    setMessageText(state,n){
        state.show = true        
        state.message = n
    },
    setMessageType(state,n){
        state.type = n
    },
    showMessage(state,n){
        state.show = n
    }
}