<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <nav class="footer-nav">
          <ul>
          <li>
           &nbsp;
          </li>
        </ul>
        </nav>
        <div class="credits ml-auto">
          <div class="copyright">
            &copy; Direitos Reservados
            <a href="https://treinamentomt.com.br" target="_blank"> &nbsp; Treinamento MT.</a>&nbsp;
            Projetado por <a href="https://treinamentomt.com.br" target="_blank">&nbsp; Treinamento MT/STI</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'main-footer'
  }
</script>
<style>
</style>
