<template>
  <model-form
    titulo="Cadastro de Modalidades"
    rota="modalidades"
    rotaVoltar="modalidades"
  ></model-form>
</template>

<script>
import ModelForm from "./forms/Model"

export default {
  components: { ModelForm }
}
</script>