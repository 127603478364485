<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
           &nbsp;
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy; Direitos Reservados
        <a href="https://treinamentomt.com.br" target="_blank"> &nbsp; Treinamento MT.</a>&nbsp;
        Projetado por <a href="https://treinamentomt.com.br" target="_blank">&nbsp; Treinamento MT/STI</a>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
