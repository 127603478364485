<template>
  <model-form
    titulo="Cadastro de Tarefas"
    rota="tasks"
    rotaVoltar="configuration/tools/task"
  ></model-form>
</template>

<script>
import ModelForm from "./forms/Model"

export default {
  components: { ModelForm },
}
</script>