<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Meus dados</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Nome"
                      :disabled="true"
                      placeholder="Nome"
                      v-model="user.nome">
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="E-mail"
                      :disabled="true"
                      placeholder="Mamp"
                      v-model="user.email">
            </fg-input>
          </div>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        user: {
          nome: '',
          email: '',
        }
      }
    },
    mounted(){
      if(localStorage.user){
        this.user.nome = JSON.parse(localStorage.user).name
        this.user.email = JSON.parse(localStorage.user).email
      }
    }
  }
</script>
<style>

</style>
