<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h4>{{ titulo }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <card>
          <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Usuário</div>
          <el-select
            class="selectForm"
            v-model="user.id"
            @change="setUserPermission"
          >
            <el-option
              v-for="(item, index) in users"
              class="select-danger"
              :value="item.id"
              :label="item.name"
              :key="index"
            ></el-option>
          </el-select>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8"></div>
              <div class="col-md-2" style="margin-top:7px">
                <el-select
                  class="selectForm"
                  v-model="searchField"
                >
                  <el-option
                    v-for="(item, index) in colunas"
                    class="select-danger"
                    :value="item.prop"
                    :label="item.label"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-md-2">
                <div style="margin-top:8px">
                  <fg-input
                    class="input-sm"
                    placeholder="Pesquisar"
                    v-model="searchQuery"
                    addon-right-icon="nc-icon nc-zoom-split"
                    @input="queriedData"
                ></fg-input>
                </div>
                <pulse-loader :loading="loading" color="black" size="3px"></pulse-loader>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 mt-2">
                <el-table
                  class="table-striped"
                  :data="this.tableData"
                  border
                  style="width: 100%"
                  :default-sort="defaultSort"
                >
                  <el-table-column
                    v-for="column in colunas"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :prop="column.prop"
                    :label="column.label"
                    sortable
                  ></el-table-column>
                  <el-table-column
                    :min-width="35"
                    align="right"
                    label="Ativo">
                    <template slot-scope="props">
                      <p-switch v-model="props.row.active" @input="toggle(props.$index, props.row)"></p-switch>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12" style="margin-top:15px">
                <el-pagination
                  @size-change="handlePerPages"
                  @current-change="handleCurrentPage"
                  :current-page.sync="pagination.current_page"
                  :page-sizes="per_pages"
                  :page-size="pagination.per_page"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pagination.total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue"
import { Table, TableColumn, Select, Option, Pagination } from "element-ui"
import PPagination from "src/components/UIComponents/Pagination.vue"
import { Card, Button, Modal } from "src/components/UIComponents"
import PulseLoader from "vue-spinner/src/PulseLoader.vue"
import laravueNotify from '@/components/ProjetoBase/Views/Components/notifications/laravueNotifications'
import cloneDeep from "clone-deep"
import PSwitch from 'src/components/UIComponents/Switch.vue'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)
export default {
  name: "PermissionDataTable",
  components: {
    PPagination,
    Card,
    Button,
    Modal,
    PulseLoader,
    PSwitch
  },
  props: {
    titulo: String,
    rota: String,
    colunas: Array,
    ordenacao: Array,
  },
  data() {
    return {
      users: [],
      user: {},
      userPermissions: [],
      searchField: this.colunas[0].prop,
      defaultSort: {},
      pagination: {
        current_page: 1,
        first_page_url: `${this.rota}?page=1`,
        from: 1,
        last_page: 1,
        last_page_url: `${this.rota}?page=1`,
        next_page_url: `${this.rota}?page=1`,
        path: '',
        per_page: 15,
        prev_page_url: null,
        to: 15,
        total: 0
      },
      per_pages: [5, 10, 15, 25, 50],
      searchQuery: "",
      tableData: [],
      loading: false
    }
  },
  methods: {
    setVisibility(key){
      return this.$can(key)
    },
    queriedData() {
      if((this.searchQuery == "") || (this.searchQuery && this.searchQuery.length > 2) ) {
        this.loading = true
        let query = `?${this.searchField}=${this.searchQuery}`
        this.$http
          .get(this.rota + query)
          .then(response => {
              this.loading = false
              this.pagination = response.data.data
              this.tableData = this.setActive(cloneDeep(this.pagination.data), this.userPermissions)
          })
          .catch(e => {
          this.loading = false
          laravueNotify.failure(this, e)
        })
      }
    },
    setUserPermission(){
      for(var i = 0; i < this.users.length; i++){
        if(this.users[i].id == this.user.id){
          this.userPermissions = cloneDeep(this.users[i].permissions)
          this.loadTableData(this.pagination.per_page, this.pagination.current_page)
          break
        }
      }
    },
    toggle(index, row) {
      if(row.active){ //give permission
        this.loading = true
        this.$http.put(`userpermissions/${this.user.id}`, { permission: row.name } )
        .then(response => {
            this.userPermissions = response.data.data
            for(var i = 0; i < this.users.length; i++){
              if(this.users[i].id == this.user.id){
                this.users[i].permissions = cloneDeep(this.userPermissions)
                break
              }
            }
            this.loading = false
        })
        .catch(e => {
            laravueNotify.failure(this, e)
          })
      } else { // revoke permission
        this.loading = true
        this.$http.delete(`userpermissions/${this.user.id}`, { data: { permission: row.name } })
        .then(response => {
            this.userPermissions = response.data.data
            for(var i = 0; i < this.users.length; i++){
              if(this.users[i].id == this.user.id){
                this.users[i].permissions = cloneDeep(this.userPermissions)
                break
              }
            }
            this.loading = false
        })
        .catch(e => {
            laravueNotify.failure(this, e)
          })
      }
    },
    setActive(all, userPermissions) {
      let optmizedPermissions = cloneDeep(userPermissions)
      for(var i=0; i < all.length; i++){
        for(var j=0; j < optmizedPermissions.length; j++) {
          if(all[i].id == optmizedPermissions[j].id) {
            all[i].active = true
            optmizedPermissions.splice(j,1)
            break
          }
        }
        if(optmizedPermissions.length == 0){
          break
        }
      }
      return all
    },
    loadTableData(perPage, page){
      let query = this.searchQuery !== '' ? `?${this.searchField}=${this.searchQuery}` : ''
      let sortQuery = ``
      let localPerPage = ``
      let localPage = `?page=${page}`
      if(this.ordenacao != undefined){
        sortQuery = query === '' ? '?' : '&'
        this.ordenacao.forEach( ordem => {
          sortQuery += `orderBy${this.$capitalize(ordem.prop)}=${ordem.order}&`
          if( ordem.default ){
            let order = ordem.order === 'ASC' ? 'ascending' : 'descending'
            this.defaultSort = { prop: ordem.prop, order }
          }
        })
        localPerPage = perPage > 0 ? `&per_page=${perPage}`: ''
      } else if( query == '' ) {
        localPerPage = perPage > 0 ? `?per_page=${perPage}`: ''
      } else {
        localPerPage = perPage > 0 ? `&per_page=${perPage}`: ''
      }
      this.loading = true
      this.$http
        .get(this.rota + query + sortQuery + localPerPage + localPage)
        .then(response => {
            this.pagination = response.data.data
            this.tableData = this.setActive(cloneDeep(this.pagination.data), this.userPermissions)
            this.loading = false
        })
        .catch(e => {
        this.loading = false
        laravueNotify.failure(this, e)
      })
    },
    handlePerPages(per_pages) {
      this.loadTableData(per_pages, this.pagination.current_page)
    },
    handleCurrentPage(to_page) {
      this.loadTableData(this.pagination.per_page, to_page)
    },
  },
  created() {
    this.loading = true
    this.$http.get('userpermissions')
      .then(response => {
          this.users = response.data.data
          this.user = cloneDeep(this.users[0])
          this.userPermissions = cloneDeep(this.user.permissions)
          // 15 registros por página começando na pagina 1
          this.loadTableData(15, this.pagination.current_page)
          this.loading = false
      })
      .catch(e => {
       laravueNotify.failure(this, e)
      });
  }
}
</script>
<style lang="scss" scoped>
.el-table .td-actions {
  button.btn {
    margin-right: 5px
  }
}
</style>
