<template>
  <div v-if="model">
    <div class="row">
		<div class="col-sm-12">
			<p>
				<b>Aluno</b>
				<br/>
				{{ model.aluno.user.name }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Data</b>
				<br/>
				{{ model.data }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Costas</b>
				<br/>
				{{ model.costas }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Tríceps</b>
				<br/>
				{{ model.triceps }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Bíceps</b>
				<br/>
				{{ model.biceps }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Abdômen Lateral</b>
				<br/>
				{{ model.abdomen_lateral }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Abdômen Frontal</b>
				<br/>
				{{ model.abdomen_frontal }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Coxa</b>
				<br/>
				{{ model.coxa }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Perna</b>
				<br/>
				{{ model.perna }}
			</p>
		</div>
	</div>
    <!-- {{ laravue-insert:field }} -->
  </div>
</template>

<script>
export default {
  name: "Show",
  props: {
    model: undefined
  }
}
</script>