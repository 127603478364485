<template>
  <div class="card card-user">
    <div class="image"></div>
    <div class="card-body">
      <div class="author">
        <a href="#">
          <img class="avatar border-gray" :src="user.avatar" alt="...">
          <h5 class="title">{{ user.name }}</h5>
        </a>
        <p class="description">
          {{ user.email }}
        </p>
      </div>
      <!-- <p class="description text-center">
        
      </p> -->
      <!-- <p>
        <image-picker @fileLoaded="loadFile"/>
      </p> -->
      <div class="row">
        <div class="col-md-6">
          <p-button @click="popAvatarModal" type="warning">alterar foto</p-button>
        </div>
        <div class="col-md-6">
          <p-button @click="popPasswordModal" type="danger">alterar senha</p-button>
        </div>
      </div>
    </div>
    <!-- <div class="card-footer">
      <hr>
      <div class="button-container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-6 ml-auto">
            <h5>12
              <br>
              <small>Cursos concluídos</small>
            </h5>
          </div>
          <div class="col-lg-6 col-md-6 col-6 ml-auto mr-auto">
            <h5>2
              <br>
              <small>Participações em eventos</small>
            </h5>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Password Modal -->
    <modal :show.sync="showPasswordModal" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">
        <p>Digite nova Senha</p>
      </h4>
      <div class="row">
        <div class="col-sm-12">
          <fg-input
            type="password"
            label="Nova Senha"
            v-model="user.password"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <fg-input
            type="password"
            label="Confirmação de Senha"
            v-model="user.password_confirmation"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <pulse-loader :loading="loading" color="teal" size="15px"></pulse-loader>
        </div>
      </div>

      <br />
      <template slot="footer">
        <div class="left-side">
          <p-button link @click="passwordModalCandel">Cancelar</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="danger" link @click="passwordModalOk">alterar</p-button>
        </div>
      </template>
    </modal>
    <!-- Avatar Modal -->
    <modal :show.sync="showAvatarModal" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">
        <p>Atualizar Foto</p>
      </h4>
      <div class="row">
        <div class="col-sm-12">
          <vue-avatar
        :width=200
        :height=200
        ref="vueavatar"
        @vue-avatar-editor:image-ready="onImageReady"
        :image="uploadImage"
      ></vue-avatar>
      <br>
      <vue-avatar-scale
        ref="vueavatarscale"
        @vue-avatar-editor-scale:change-scale="onChangeScale"
        :width=250
        :min=1
        :max=3
        :step=0.02
      >
      </vue-avatar-scale>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          {{ sendingStatus }} <pulse-loader :loading="loading" color="teal" size="15px"></pulse-loader>
        </div>
      </div>
      <template slot="footer">
        <div class="left-side">
          <p-button link @click="avatarModalCandel">Cancelar</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="danger" link @click="avatarModalOk">alterar</p-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import ImagePicker from './ImagePicker'
import laravueNotify from "@/components/ProjetoBase/Views/Components/notifications/laravueNotifications"
import { Modal } from "src/components/UIComponents"
import PulseLoader from "vue-spinner/src/PulseLoader";
import VueAvatar from "@/components/ProjetoBase/Views/Components/VueAvatar";
import VueAvatarScale from "@/components/ProjetoBase/Views/Components/VueAvatarScale";

  export default {
    components:{
        ImagePicker, Modal, PulseLoader, VueAvatar, VueAvatarScale
    },
    props: {
      uploadImage: {
          type: String,
          default: require("@/assets/icons/upload_user.png")
        }
    },
    data(){
      return {
        sendingStatus: '',
        loading: false,
        showPasswordModal: false,
        showAvatarModal: false,
        user: {
          name: '',
          email: '',
          avatar: '',
          password: '',
          password_confirmation: ''
        },
        foto: undefined
      }
    },
    mounted() {
      if(localStorage.user){
        let storageUser = JSON.parse(localStorage.user)
        this.user.id = this.CryptoJS.AES.decrypt(storageUser.id, this.$secretKey).toString(this.CryptoJS.enc.Utf8)
        this.user.avatar_id = this.CryptoJS.AES.decrypt(storageUser.avatar_id, this.$secretKey).toString(this.CryptoJS.enc.Utf8)
        this.user.name = storageUser.name
        this.user.email = storageUser.email
        this.user.avatar = storageUser.avatar
      }
    },
    methods: {
      setUploadImage(image){
        return `url(${image})`
      },
      popPasswordModal(){
        this.showPasswordModal = true
      },
      passwordModalOk() {
        this.loading = true
        this.submitPassword()
      },
      passwordModalCandel() {
        this.showPasswordModal = false
      },
      submitPassword(){
         this.$http({
            method: "put",
            url: `users/${this.user.id}`,
            data: {
              name: this.user.name,
              email: this.user.email,
              password: this.user.password,
              password_confirmation: this.user.password_confirmation,
            }
          })
            .then(response => {
              this.loading = false
              console.log(response.data)
              this.showPasswordModal = false
              laravueNotify.message(this, "Senha alterada com sucesso.");
            })
            .catch(e => {
              this.loading = false
              laravueNotify.failure(this, e)
            });
      },
      submitFile() {
        this.sendingStatus = 'enviando arquivo '
        this.loading = true

        this.$http.post('fileavatars', {
          file_id: this.user.avatar_id,
          user_id: this.user.id,
          user_name: this.user.name,
          image: this.$refs.vueavatar.getImageScaled().toDataURL()
        })
          .then(response => {
              this.sendingStatus = 'alterando foto '
              let file = response.data.data
              this.updateAvatar(file)
          })
          .catch(e => {
            this.loading = false
            console.log(e)
            laravueNotify.failure(this, e)
          })
     },
     updateAvatar(file){
       console.log(file)
       this.$http.put(`avatar/${this.user.id}`, { 
         avatar_id: file.id 
         })
        .then(response => {
          let storageUser = JSON.parse(localStorage.user)

          storageUser.avatar_id = this.CryptoJS.AES.encrypt(JSON.stringify(file.id), this.$secretKey).toString()
          storageUser.avatar = file.url + file.path
          localStorage.setItem('user', JSON.stringify(storageUser))

          this.user.avatar = file.url + file.path

          this.sendingStatus = ''
          this.loading = false
          this.showAvatarModal = false
          laravueNotify.message(this, "Foto alterada com sucesso.");
        })
        .catch(e => {
            this.sendingStatus = ''
            this.loading = false
            console.log(e)
            laravueNotify.failure(this, e)
          })
     },
      popAvatarModal(){
        this.showAvatarModal = true
      },
      avatarModalOk() {
        this.submitFile()
      },
      avatarModalCandel() {
        this.showAvatarModal = false
      },
      onChangeScale (scale) {
        this.$refs.vueavatar.changeScale(scale)
    },
    saveClicked(){
      var img = this.$refs.vueavatar.getImageScaled().toDataURL()
      console.log(img)
    },
    onImageReady(scale){
      this.$refs.vueavatarscale.setScale(scale)
    }
    }

  }

</script>
<style>

</style>
