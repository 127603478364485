<template>
  <model-form
    titulo="Edição de Dobras Cutâneas"
    rota="dobracutaneas"
    rotaVoltar="dobracutaneas"
    edicao
  ></model-form>
</template>

<script>
import ModelForm from "./forms/Model"

export default {
  components: { ModelForm }
}
</script>