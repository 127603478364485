<template>
  <model-form
    titulo="Edição de Funções"
    rota="roles"
    rotaVoltar="configuration/security/role"
    edicao
  ></model-form>
</template>

<script>
import ModelForm from "./forms/Model"

export default {
  components: { ModelForm },
}
</script>