<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar"></navbar-toggle-button>
      </div>
    </div>
    <a class="navbar-brand" href="#">Gerenciamento</a>
    <template slot="navbar-menu">
      <el-avatar v-show="userLogged" :size="35" :src="user.avatar" style="vertical-align:middle"></el-avatar>
      <ul class="navbar-nav">
        <drop-down icon="nc-icon nc-settings-gear-65" tag="li"
                   position="right"
                   direction="none"
                   class="nav-item btn-rotate dropdown"
                   v-show="userLogged">
          <a slot="title"
             slot-scope="{isOpen}"
             class="nav-link dropdown-toggle"
             data-toggle="dropdown"
             aria-haspopup="true"
             :aria-expanded="isOpen">
             {{ user.name }}
          </a>
          <router-link class="dropdown-item" :to="routeUserPerfil"> Meus Dados </router-link>
          <a class="dropdown-item" @click="logoutUser" href="#">Sair</a>
         <div style="text-align:center">
           <router-link class="dropdown-item" :to="routeRoadmap"><small>versão <b>{{ currentVersion }}</b></small></router-link>
         </div>
        </drop-down>
        <drop-down 
            v-if="$can(`ver menu configuracao`)"
            icon="nc-icon nc-settings-gear-65" tag="li"
            class="nav-item dropdown"
            position="right"
            direction="none"
            hideArrow
            v-show="userLogged"> 
            <small v-if="$can(`ver menu seguranca`) || $can(`ver menu ferramentas`)">
              <div style="text-align:center;margin-top:8px"><strong>Gerenciamento</strong></div>
            </small>
            <router-link v-if="$can(`ver menu seguranca`)" class="dropdown-item" :to="routeSecurity"><span><i class="el-icon-lock"></i></span>  Segurança</router-link>
            <router-link v-if="$can(`ver menu ferramentas`)" class="dropdown-item" :to="routeTools"><span><i class="el-icon-set-up"></i></span>  Ferramentas</router-link>
            <hr v-if="$can(`ver menu seguranca`) || $can(`ver menu ferramentas`)">
            <small><div style="text-align:center"><strong>Cadastro Auxiliar</strong></div></small>
            <router-link v-if="$can(`ver menu modalidades`)" class="dropdown-item" :to="rotaModalidades"><span><i class="el-icon-connection"></i></span>  Modalidades</router-link>
        </drop-down>
      </ul>
    </template>
  </navbar>
</template>
<script>
  import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
  import { CollapseTransition } from 'vue2-transitions'
  import { Avatar } from "element-ui"
  import laravueNotify from "@/components/ProjetoBase/Views/Components/notifications/laravueNotifications";

  export default {
    components: {
      Navbar,
      NavbarToggleButton,
      CollapseTransition,
      [Avatar.name]: Avatar
    },
    data() {
      return {
        currentVersion: 'versão',
        routeUserPerfil: '/paginas/users/show',
        routeRoadmap: '/paginas/roadmap/show',
        routeUserLogin: '',
        activeNotifications: false,
        showNavbar: false,
        isClosed: true,
        userLogged: localStorage.user ? true : false,
        user: {},
        routeSecurity: '/paginas/configuration/security/user',
        routeTools: '/paginas/configuration/tools/task',
        rotaModalidades: '/paginas/modalidades',
      }
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown() {
        this.activeNotifications = false
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize()
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar
      },
      loginUser(){
        location.href = this.$urlHOME
      },
      logoutUser(){
        localStorage.clear()
        this.$router.push( { name: 'Login' } )
      }
    },
    mounted() {
      if( localStorage.user ) {
        // user
        this.user = JSON.parse(localStorage.user)

        // version
        this.$http.get('currentversions').then( response => {
            this.currentVersion = response.data.data
        }).catch(e => {
            laravueNotify.failure(this, e)
        })
      } 
    },
  }

</script>
<style scoped>
.dropdown-item.active, .dropdown-item:active {
  background-color: #ef8157!important;
}
</style>
