<template>
  <model-form
    titulo="Relatório de Avaliações Físicas"
    rota="avaliacaofisicas"
    rotaVoltar="avaliacaofisicas"
    relatorio
  ></model-form>
</template>

<script>
import ModelForm from "./forms/Model"

export default {
  components: { ModelForm }
}
</script>