<template>
  <div v-if="model">
    <div class="row">
		<div class="col-sm-12">
			<p>
				<b>Nome</b>
				<br/>
				{{ model.user.name }}
			</p>
		</div>
		<div class="col-sm-12">
			<p>
				<b>E-mail</b>
				<br/>
				{{ model.user.email }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Data Admissão</b>
				<br/>
				{{ model.data_admissao }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Data Nascimento</b>
				<br/>
				{{ model.data_nascimento }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Cpf</b>
				<br/>
				{{ model.cpf }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Rg</b>
				<br/>
				{{ model.rg }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Pa</b>
				<br/>
				{{ model.pa }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Patologias</b>
				<br/>
				{{ model.patologias }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Telefone 1</b>
				<br/>
				{{ model.telefone1 }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Telefone 2</b>
				<br/>
				{{ model.telefone2 }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Nome Pai</b>
				<br/>
				{{ model.nome_pai }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Nome Mae</b>
				<br/>
				{{ model.nome_mae }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Logradouro</b>
				<br/>
				{{ model.logradouro }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Num Logradouro</b>
				<br/>
				{{ model.num_logradouro }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Complemento Logradouro</b>
				<br/>
				{{ model.complemento_logradouro }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Bairro</b>
				<br/>
				{{ model.bairro }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Cidade</b>
				<br/>
				{{ model.cidade }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Estado</b>
				<br/>
				{{ model.estado }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Cep</b>
				<br/>
				{{ model.cep }}
			</p>
		</div>
	</div>
    <div v-if="model.modalidades.length > 0" class="row">
		<div class="col-sm-12">
			<p>
				<b>Modalidades</b>
				<br/>
				<ul>
					<li v-for="(modalidade, key) in model.modalidades" :key="key">{{ modalidade.nome }}</li>
				</ul>
			</p>
		</div>
	</div>
	<!-- {{ laravue-insert:field }} -->
  </div>
</template>

<script>
export default {
  name: "Show",
  props: {
    model: undefined
  }
}
</script>