<template>
  <div v-if="model">
    <div class="row">
      <div class="col-sm-12">
        <p>
          <b>Mensagem:</b>
          <br/>
          {{ model.mensagem }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <p>
          <b>Classe : metodo : linha:</b>
          <br/>
          {{ model.classe }} : {{ model.metodo }} : {{ model.linha }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <p>
          <b>Data (atualização):</b>
          <br/>
          {{ model.updated_at }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <p>
          <b>IP:</b>
          <br/>
          {{ model.ip }}
        </p>
      </div>
    </div>
      <div class="row">
      <div class="col-sm-12">
        <p>
          <b>User agent:</b>
          <br/>
          {{ model.user_agent }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Show",
  props: {
    model: undefined
  }
}
</script>
