<template>
  <model-form
    titulo="Cadastro de Usuários"
    rota="users"
    rotaVoltar="configuration/security/user"
  ></model-form>
</template>

<script>
import ModelForm from "./forms/Model"

export default {
  components: { ModelForm }
}
</script>