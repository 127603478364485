<template>
  <div>
    <div v-if="titulo" class="row">
      <div class="col-md-12">
        <h4>{{ titulo }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <div v-if="$can(`editar ${rota}`)">
                  <div v-if="!noactions">
                    <router-link :to="rotaNovoRegistro" v-if="!noactions">
                      <p-button type="success"><i class="fa fa-plus"></i>&nbsp; inserir</p-button>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <slot name="actions"></slot>
              </div>
              <div class="col-md-2" style="margin-top:7px">
                <el-select
                  class="selectForm"
                  v-model="searchField"
                >
                  <el-option
                    v-for="(item, index) in colunas"
                    class="select-danger"
                    :value="getFilter(item.prop, item.filter, item.fieldFilter, item.type)"
                    :label="item.label"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-md-2">
                <div style="margin-top:8px">
                  <fg-input
                    class="input-sm"
                    placeholder="Pesquisar"
                    v-model="searchQuery"
                    addon-right-icon="nc-icon nc-zoom-split"
                    @input="queriedData"
                ></fg-input>
                </div>
                <pulse-loader :loading="loading" color="black" size="3px"></pulse-loader>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 mt-2">
                <el-table
                  class="table-striped"
                  :data="this.tableData"
                  border
                  style="width: 100%"
                  :default-sort="defaultSort"
                  @sort-change="sortChange"
                >
                  <el-table-column
                    v-for="column in colunas"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :prop="column.prop"
                    :label="column.label"
                    :sortable="column.sortable || column.sortable === undefined"
                  ></el-table-column>
                <el-table-column
                  :min-width="minWidth"
                  fixed="right"
                  class-name="td-actions justify-content-center"
                  label="Ações"
                  v-if="!noactions && ( $can(`ver ${rota}`) || $can(`editar ${rota}`) || $can(`apagar ${rota}`) )">
                    <template slot-scope="props">
                      <div v-if="!readonly">
                        <p-button
                          v-if="overrideShow"
                          v-show="$can(`ver ${rota}`)"
                          type="info"
                          size="sm"
                          icon
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Detalhar"
                          @click="handleShow(props.$index, props.row)"
                        >
                          <i class="nc-icon nc-bullet-list-67"></i>
                        </p-button>
                        <p-button
                          v-show="$can(`editar ${rota}`)"
                          type="warning"
                          size="sm"
                          icon
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Editar"
                          @click="handleEdit(props.$index, props.row)"
                        >
                          <i class="fa fa-edit"></i>
                        </p-button>
                        <p-button
                          v-show="$can(`apagar ${rota}`)"
                          type="danger"
                          size="sm"
                          icon
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Excluir"
                          @click="handleDelete(props.$index, props.row)"
                        >
                          <i class="fa fa-times"></i>
                        </p-button>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-10" style="margin-top:15px">
                <el-pagination
                  @size-change="handlePerPages"
                  @current-change="handleCurrentPage"
                  :current-page.sync="pagination.current_page"
                  :page-sizes="per_pages"
                  :page-size="pagination.per_page"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pagination.total">
                </el-pagination>
              </div>
              <div class="col-sm-2" style="margin-top:15px; text-align: right;">
                <router-link v-if="$can(`imprimir ${rota}`)"  :to="rotaReport">
                  <p-button type="primary" size="sm">
                    <i class="el-icon-printer"> IMPRIMIR</i>
                  </p-button>
                </router-link>
              </div>
              <div class="col-sm-2" style="margin-top:15px; text-align: right;">
                <el-tooltip content="Relatório em Excel" placement="top">
                  <p-button type="success" size="sm" v-on:click="getReport('xlsx')" v-if="showXls && ($can(`ver ${rota}`))">
                    <i class="fa fa-file-excel-o"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip content="Relatório em PDF" placement="top">
                  <p-button type="danger" size="sm" v-on:click="getReport('pdf')" v-if="showPdf && ($can(`ver ${rota}`))">
                    <i class="fa fa-file-pdf-o"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip content="Gerar Relatório" placement="top">
                  <router-link :to="rotaReport" v-if="showReportView && ($can(`ver ${rota}`))">
                    <p-button type="primary" size="sm">
                      <i class="fa fa-file-pdf-o"></i>
                    </p-button>
                  </router-link>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Show Modal -->
    <modal :show.sync="showModalShow" headerClasses="justify-content-center">
      <h6 slot="header" class="title title-up">{{ titulo }}</h6>
      <p>
        <slot name="modalShow" v-bind:model="model"></slot>
      </p>
      <template slot="footer">
        <div class="left-side"></div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="warning" link @click="modalShowClose">Fechar</p-button>
        </div>
      </template>
    </modal>
    <!-- Delete Modal -->
    <modal :show.sync="showModalDelete" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">
        <p-button type="danger" icon round>
          <i class="nc-icon nc-simple-remove"></i>
        </p-button>
        <p>Confirma exclusão?</p>
      </h4>
      <p v-if="overrideDelete">
        <slot name="modalDelete" v-bind:model="model"></slot>
      </p>
      <p v-else>
        <b>{{ this.selectedItem }}</b>
      </p>
      <p>Será excluído permanentemente!</p>
      <pulse-loader :loading="loading" color="red" size="3px"></pulse-loader>
      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="modalDeleteCandel">Cancelar</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="danger" link @click="modalDeleteOk">Excluir</p-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from "vue"
import { Table, TableColumn, Select, Option, Pagination, Tooltip } from "element-ui"
import PPagination from "src/components/UIComponents/Pagination.vue"
import { Card, Button, Modal } from "src/components/UIComponents"
import PulseLoader from "vue-spinner/src/PulseLoader.vue"
import laravueNotify from '@/components/ProjetoBase/Views/Components/notifications/laravueNotifications'
import cloneDeep from "clone-deep"

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)
export default {
  name: "LaravueDataTable",
  components: {
    PPagination,
    Card,
    Button,
    Modal,
    PulseLoader,
    [Tooltip.name]: Tooltip
  },
  props: {
    titulo: String,
    rota: String,
    colunas: Array,
    ordenacao: Array,    
    showPdf: {
      type: Boolean,
      default: false
    },
    showXls: {
      type: Boolean,
      default: false
    },
    showReportView: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideDelete: {
      type: Boolean,
      default: false
    },
    overrideShow: {
      type: Boolean,
      default: false
    },
    deleted: {
      type: Number,
      default: 0
    },
    noactions: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    deleted() {
      this.$http
        .get(this.rota)
        .then(response => {
          this.tableData = response.data.data.data
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          laravueNotify.failure(this, e)

        })
    }
  },
  data() {
    return {
      searchField: this.getFilter(this.colunas[0].prop, this.colunas[0].filter, this.colunas[0].fieldFilter, this.colunas[0].type),
      rotaNovoRegistro: "/paginas/" + this.rota + "/create",
      rotaReport: "/paginas/" + this.rota + "/report",
      defaultSort: {},
      orderAuxiliar: undefined,
      propAuxiliar: undefined,
      pagination: {
        current_page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
        first_page_url: `${this.rota}?page=1`,
        from: 1,
        last_page: 1,
        last_page_url: `${this.rota}?page=1`,
        next_page_url: `${this.rota}?page=1`,
        path: '',
        per_page: 15,
        prev_page_url: null,
        to: 15,
        total: 0
      },
      per_pages: [5, 10, 15, 25, 50],
      searchQuery: "",
      tableData: [],
      showModalDelete: false,
      showModalShow: false,
      selectedItem: undefined,
      loading: false,
      model: undefined,
    }
  },
  methods: {
    sortChange(sortProps){
      this.orderAuxiliar = sortProps.order === 'ascending' ? 'ASC' : 'DESC'
      this.propAuxiliar = sortProps.prop

      this.loadTableData(this.pagination.current_page)
    },
    queriedData() {
      if( (this.searchQuery == "") || (this.searchQuery && this.searchQuery.length > 2 ) ) {
        this.loading = true
        let query = `?${this.searchField}${this.searchQuery}`
        query = this.searchField.includes(this.defaultFilter) ? query + '}}' : query
        this.$http
          .get(this.rota + query)
          .then(response => {
              this.loading = false
              this.pagination = response.data.data
              this.tableData = cloneDeep(this.pagination.data)
          })
          .catch(e => {
          this.loading = false
          laravueNotify.failure(this, e)
        })
      }
    },
    loadTableData(page){
      let query = this.searchQuery !== '' ? `?${this.searchField}${this.searchQuery}` : ''
      
      let sortQuery = `?`      
      let localPage = `page=${page}`
      let hasSort = this.orderAuxiliar != undefined && this.propAuxiliar != undefined
      let currentSort = null

      if(this.ordenacao != undefined || hasSort){
        sortQuery = query === '' ? '?' : '&'

        this.ordenacao.forEach( ordem => {
          let orderField = ordem.alias != undefined ? ordem.alias : ordem.prop
          currentSort = currentSort == null ? ordem.prop : currentSort

          if(hasSort && this.propAuxiliar === ordem.prop){
            currentSort = ordem.prop
            orderField = ordem.alias != undefined ? ordem.alias : this.propAuxiliar
            sortQuery += `orderBy${this.$capitalize(orderField)}=${this.orderAuxiliar}&`
            return
          }
          sortQuery += `orderBy${this.$capitalize(orderField)}=${ordem.order}&`
          if( ordem.default ){
            let order = ordem.order === 'ASC' ? 'ascending' : 'descending'
            this.defaultSort = { prop: orderField, order }
          }
        })        
      } 
      this.$http
        .get(this.rota + query + sortQuery + localPage)
        .then(response => {
            this.pagination = response.data.data
            let deepData = cloneDeep(this.pagination.data)

            if(currentSort != null){
              deepData.forEach( data => {
                  let prop = data[currentSort]
                  if(typeof prop === 'string' || prop instanceof String){
                    let char = prop.charAt(0)
                    char = this.removeAccents(char)
                    prop = char + prop.slice(1)
                    data[currentSort] = prop
                  }
              })
            }

            this.tableData = deepData
        })
        .catch(e => {
        this.loading = false
        laravueNotify.failure(this, e)
      })
    },
    handlePerPages(per_pages) {
      this.loadTableData(this.pagination.current_page)
    },
    handleCurrentPage(to_page) {
      this.loadTableData(to_page)
    },
    modalDeleteOk() {
      this.loading = true
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === this.model.id
      )
      if (this.model.id > 0) {
        this.$http
          .delete(`${this.rota}/${this.model.id}`)
          .then(response => {
              this.tableData.splice(indexToDelete, 1)
              this.showModalDelete = false
              this.loading = false
          })
          .catch(e => {
              this.loading = false
              laravueNotify.failure(this, e)
            })
      }
    },
    modalDeleteCandel() {
      this.showModalDelete = false
    },
    modalShowClose() {
      this.showModalShow = false
    },
    handleEdit(index, row) {
      let modelId = row.id
      this.$router.push({ path: `/paginas/${this.rota}/edit/${modelId}?page=${this.pagination.current_page}` })
    },
    handleShow(index, row) {
      this.model = row
      this.showModalShow = true
    },
    handleDelete(index, row) {
      this.model = row

      this.selectedItem = row.id
      if (row.name) {
        this.selectedItem = row.name
      } else if (row.nome) {
        this.selectedItem = row.nome
      } else if (row.titulo) {
        this.selectedItem = row.titulo
      } else if (row.descricao) {
        this.selectedItem = row.descricao
      } else  {
        this.selectedItem = "O item selecionado"
      }

      this.showModalDelete = true
    },
    getReport(modelo) {
      let apiUrl = this.$http.defaults.baseURL
      this.$http
        .get(`${apiUrl}reports/${this.rota}/${modelo}`, {responseType: 'arraybuffer'})
        .then(response => {
          this.$getReport(response, modelo)
        })
        .catch(e => {
          this.loading = false  
          laravueNotify.failure(this, e)
        })
    },
    getFilter(prop, filter, fieldFilter, typeField) {
      fieldFilter = fieldFilter || prop
      fieldFilter = typeField ? fieldFilter + '{{' + typeField + '}}' : fieldFilter
      return filter ? filter + '=' : this.defaultFilter + '=' + fieldFilter + '{{'
    },
    removeAccents(strAccents) {
      strAccents = strAccents.split('')
      var strAccentsOut = new Array()
      var strAccentsLen = strAccents.length
      var accents =    "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž"
      var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz"
      for (var y = 0; y < strAccentsLen; y++) {
          if (accents.indexOf(strAccents[y]) != -1) {
              strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1)
          } else
              strAccentsOut[y] = strAccents[y]
      }
      strAccentsOut = strAccentsOut.join('')
      return strAccentsOut
    }
  },
  computed: {
    minWidth() {
      let min = 0
      min = this.overrideShow && 30        
      min = this.$can(`editar ${this.rota}`) && 30
      min = this.$can(`apagar ${this.rota}`) && 30
      min = ( this.overrideShow && this.$can(`editar ${this.rota}`) ) && 40
      min = ( this.overrideShow && this.$can(`editar ${this.rota}`) && this.$can(`apagar ${this.rota}`) ) && 60

       return min
    },
  },
  created() {
    this.loadTableData(this.pagination.current_page)
  },
  beforeCreate() {
    this.defaultFilter = 'field_like'
  }
}
</script>
<style lang="scss" scoped>
.el-table .td-actions {
  button.btn {
    margin-right: 5px
  }
}
</style>
