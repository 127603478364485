<template>
  <model-form
    titulo="Edição de Modalidades"
    rota="modalidades"
    rotaVoltar="modalidades"
    edicao
  ></model-form>
</template>

<script>
import ModelForm from "./forms/Model"

export default {
  components: { ModelForm }
}
</script>