const serviceGetReport = {
    install(Vue, options) {
      Vue.prototype.$getReport = function(response, typeFile) {
        let fileName = response.headers['content-disposition'].match(/filename=(.*)/)[1];
        let blob = new Blob([response.data], { type: `application/` + typeFile })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName.replace(/"/g, '');
        link.click();
      }
    }
  }
  
  export default serviceGetReport;