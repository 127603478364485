import PainelLaravueLayout from '../components/Laravue/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
import NotAuth from '../components/GeneralViews/NotAuthPage.vue'

// Páginas do Projeto Base
import Login from 'src/components/ProjetoBase/Views/Pages/Login.vue'
import Logout from 'src/components/ProjetoBase/Views/Pages/Logout.vue'
import MonitorIndex from 'src/components/ProjetoBase/Views/Pages/Monitor/Index.vue'
import MonitorReport from 'src/components/ProjetoBase/Views/Pages/Monitor/Report.vue'
import UserIndex from 'src/components/ProjetoBase/Views/Pages/User/Index.vue'
import UserCreate from 'src/components/ProjetoBase/Views/Pages/User/Create.vue'
import UserEdit from 'src/components/ProjetoBase/Views/Pages/User/Edit.vue'
import UserShow from 'src/components/ProjetoBase/Views/Pages/User/Show.vue'
import UserRole from 'src/components/ProjetoBase/Views/Pages/User/Role.vue'
import UserPermission from 'src/components/ProjetoBase/Views/Pages/User/Permission.vue'
import UserReport from 'src/components/ProjetoBase/Views/Pages/User/Report.vue'
import PainelControleIndex from 'src/components/ProjetoBase/Views/Pages/PainelControle/Index.vue'
import RoleIndex from 'src/components/ProjetoBase/Views/Pages/Role/Index.vue'
import RoleCreate from 'src/components/ProjetoBase/Views/Pages/Role/Create.vue'
import RoleEdit from 'src/components/ProjetoBase/Views/Pages/Role/Edit.vue'
import RolePermission from 'src/components/ProjetoBase/Views/Pages/Role/Permission.vue'
import PermissionIndex from 'src/components/ProjetoBase/Views/Pages/Permission/Index.vue'
import PermissionCreate from 'src/components/ProjetoBase/Views/Pages/Permission/Create.vue'
import PermissionEdit from 'src/components/ProjetoBase/Views/Pages/Permission/Edit.vue'
import TaskIndex from 'src/components/ProjetoBase/Views/Pages/Task/Index.vue'
import TaskCreate from 'src/components/ProjetoBase/Views/Pages/Task/Create.vue'
import TaskEdit from 'src/components/ProjetoBase/Views/Pages/Task/Edit.vue'
import TaskReport from 'src/components/ProjetoBase/Views/Pages/Task/Report.vue'
import RoadMapShow from 'src/components/ProjetoBase/Views/Pages/RoadMap/Show.vue'
import ConfigTools from 'src/components/ProjetoBase/Views/Pages/Configuration/Tools.vue'
import ConfigSecurity from 'src/components/ProjetoBase/Views/Pages/Configuration/Security.vue'

// >>> LARAVUE

// Modalidade
import ModalidadeCreate from 'src/components/TreinamentoMT/Views/Pages/Modalidade/Create.vue'
import ModalidadeEdit from 'src/components/TreinamentoMT/Views/Pages/Modalidade/Edit.vue'
import ModalidadeIndex from 'src/components/TreinamentoMT/Views/Pages/Modalidade/Index.vue'
import ModalidadeReport from 'src/components/TreinamentoMT/Views/Pages/Modalidade/Report.vue'

// Aluno
import AlunoCreate from 'src/components/TreinamentoMT/Views/Pages/Aluno/Create.vue'
import AlunoEdit from 'src/components/TreinamentoMT/Views/Pages/Aluno/Edit.vue'
import AlunoIndex from 'src/components/TreinamentoMT/Views/Pages/Aluno/Index.vue'
import AlunoReport from 'src/components/TreinamentoMT/Views/Pages/Aluno/Report.vue'

// AvaliacaoFisica
import AvaliacaoFisicaCreate from 'src/components/TreinamentoMT/Views/Pages/AvaliacaoFisica/Create.vue'
import AvaliacaoFisicaEdit from 'src/components/TreinamentoMT/Views/Pages/AvaliacaoFisica/Edit.vue'
import AvaliacaoFisicaIndex from 'src/components/TreinamentoMT/Views/Pages/AvaliacaoFisica/Index.vue'
import AvaliacaoFisicaReport from 'src/components/TreinamentoMT/Views/Pages/AvaliacaoFisica/Report.vue'

// DobraCutanea
import DobraCutaneaCreate from 'src/components/TreinamentoMT/Views/Pages/DobraCutanea/Create.vue'
import DobraCutaneaEdit from 'src/components/TreinamentoMT/Views/Pages/DobraCutanea/Edit.vue'
import DobraCutaneaIndex from 'src/components/TreinamentoMT/Views/Pages/DobraCutanea/Index.vue'
import DobraCutaneaReport from 'src/components/TreinamentoMT/Views/Pages/DobraCutanea/Report.vue'

// {{ laravue-insert:import }}

// <<< LARAVUE

// Projeto Base
let projetoBaseMenu = {
  path: '/paginas',
  component: PainelLaravueLayout,
  redirect: '/paginas/monitor',
  children: [
    {
      path: 'monitors',
      name: 'Monitor',
      component: MonitorIndex
    },
    {
      path: 'monitors/report',
      name: 'Imprimir Monitor',
      component: MonitorReport
    },
    {
      path: 'users',
      name: 'Usuários',
      component: UserIndex
    },
    {
      path: 'users/create',
      name: 'Cadastrar Usuário',
      component: UserCreate
    },
    {
      path: 'users/edit/:modelId',
      name: 'Editar Usuário',
      component: UserEdit
    },
    {
      path: 'users/show',
      name: 'Mostrar Usuário',
      component: UserShow
    },
    {
      path: 'users/role',
      name: 'Vínculo de Usuário a Função',
      component: UserRole
    },
    {
      path: 'users/permission',
      name: 'Vínculo de Usuário a Permissão',
      component: UserPermission
    },
    {
      path: 'users/report',
      name: 'Imprimir Usuário',
      component: UserReport
    },
    // Roles
    {
      path: 'roles',
      name: 'Funçoões',
      component: RoleIndex
    },
    {
      path: 'roles/create',
      name: 'Nova Funções',
      component: RoleCreate
    },
    {
      path: 'roles/edit/:modelId',
      name: 'Editar Funções',
      component: RoleEdit
    },
    {
      path: 'rolesPermission',
      name: 'Vinculação de Permissões',
      component: RolePermission
    },
    // Permissions
    {
      path: 'permissions',
      name: 'Permissões',
      component: PermissionIndex
    },
    {
      path: 'permissions/create',
      name: 'Nova Permissão',
      component: PermissionCreate
    },
    {
      path: 'permissions/edit/:modelId',
      name: 'Editar Permissão',
      component: PermissionEdit
    },
    // Tasks
    {
      path: 'tasks',
      name: 'Tarefas',
      component: TaskIndex
    },
    {
      path: 'tasks/create',
      name: 'Nova Tarefa',
      component: TaskCreate
    },
    {
      path: 'tasks/edit/:modelId',
      name: 'Editar Tarefas',
      component: TaskEdit
    },
    {
      path: 'tasks/report',
      name: 'Imprimir Tarefa',
      component: TaskReport
    },
    {
      path: 'roadmap/show',
      name: 'Roteiro do Sistema',
      component: RoadMapShow
    },
    // Configuration
    {
      path: 'configuration/tools/:tab',
      name: 'Ferramenta de Desenvolvimento',
      component: ConfigTools
    },
    {
      path: 'configuration/security/:tab',
      name: 'Segurança',
      component: ConfigSecurity
    },
  ]
}

// >>> LARAVUE
let laravueMenu = {
  path: '/paginas',
  component: PainelLaravueLayout,
  redirect: '/paginas/painelControle',
  children: [
    {
      path: 'painelControle',
      name: 'painelControle',
      component: PainelControleIndex
    },
    // Modalidades
		{
			path: 'modalidades/create', 
			name: 'Inserir Modalidades', 
			component: ModalidadeCreate, 
		},
		{
			path: 'modalidades/edit/:modelId', 
			name: 'Editar Modalidades', 
			component: ModalidadeEdit, 
		},
		{
			path: 'modalidades', 
			name: 'Modalidades', 
			component: ModalidadeIndex, 
		},
		{
			path: 'modalidades/report', 
			name: 'Imprimir Modalidades', 
			component: ModalidadeReport, 
		},
		// Alunos
		{
			path: 'alunos/create', 
			name: 'Inserir Alunos', 
			component: AlunoCreate, 
		},
		{
			path: 'alunos/edit/:modelId', 
			name: 'Editar Alunos', 
			component: AlunoEdit, 
		},
		{
			path: 'alunos', 
			name: 'Alunos', 
			component: AlunoIndex, 
		},
		{
			path: 'alunos/report', 
			name: 'Imprimir Alunos', 
			component: AlunoReport, 
		},
		// AvaliacaoFisicas
		{
			path: 'avaliacaofisicas/create', 
			name: 'Inserir AvaliacaoFisicas', 
			component: AvaliacaoFisicaCreate, 
		},
		{
			path: 'avaliacaofisicas/edit/:modelId', 
			name: 'Editar AvaliacaoFisicas', 
			component: AvaliacaoFisicaEdit, 
		},
		{
			path: 'avaliacaofisicas', 
			name: 'AvaliacaoFisicas', 
			component: AvaliacaoFisicaIndex, 
		},
		{
			path: 'avaliacaofisicas/report', 
			name: 'Imprimir AvaliacaoFisicas', 
			component: AvaliacaoFisicaReport, 
		},
		// DobraCutaneas
		{
			path: 'dobracutaneas/create', 
			name: 'Inserir DobraCutaneas', 
			component: DobraCutaneaCreate, 
		},
		{
			path: 'dobracutaneas/edit/:modelId', 
			name: 'Editar DobraCutaneas', 
			component: DobraCutaneaEdit, 
		},
		{
			path: 'dobracutaneas', 
			name: 'DobraCutaneas', 
			component: DobraCutaneaIndex, 
		},
		{
			path: 'dobracutaneas/report', 
			name: 'Imprimir DobraCutaneas', 
			component: DobraCutaneaReport, 
		},
		// {{ laravue-insert:routes }}
  ]
}
// <<< LARAVUE

let notAuthPage = {
  path: '/notAuth',
  name: 'NotAuth',
  component: NotAuth
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let logoutPage = {
  path: '/logout',
  name: 'Logout',
  component: Logout
}

const routes = [
  loginPage,
  logoutPage,
  projetoBaseMenu,
  laravueMenu,
  {
    path: '/',
    component: PainelLaravueLayout,
    redirect: '/paginas/painelControle',
  },
  notAuthPage,
  {path: '*', component: NotFound}
]

export default routes