<template>
  <div>
    <laravue-datatable
      titulo="Usuários"
      rota="users"
      :colunas = "colunas">
      <template #actions>
        <router-link v-if="$can('editar roles users')" to="/paginas/users/role">
          <p-button type="info" >
            <i class="nc-icon nc-key-25"></i>&nbsp; Funções
          </p-button>
        </router-link>
        <router-link v-if="$can('editar permissions users')" to="/paginas/users/permission">
          <p-button type="warning" >
            <i class="nc-icon nc-key-25"></i>&nbsp; Permissões
          </p-button>
        </router-link>
      </template>
    </laravue-datatable>
  </div>
</template>

<script>
import LaravueDatatable from "@/components/ProjetoBase/Views/Components/LaravueDataTable"

export default {
  name: "Index.vue",
  components: { LaravueDatatable },
  data() {
    return {
      colunas: [
        {
          prop: "name",
          label: "Nome",
          minWidth: 250,
          showDelete: true
        },
        {
          prop: "email",
          label: "E-mail",
          minWidth: 150,
          showDelete: false
        }
      ]
    }
  }
}
</script>
