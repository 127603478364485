const serviceCan = {
  install(Vue, options) {
    Vue.prototype.$can = function(decriptedKey) {
      if( !localStorage.user ){
        this.$router.push( { name: 'Login'} )
      }
      if(typeof decriptedKey === 'boolean'){
        return decriptedKey
      }
      let canDo = false
      let user = JSON.parse(localStorage.user)

      if( user ) {
        let planKeys = []
        for (var i = 0; i < user.keys.length; i++){
          planKeys.push( this.CryptoJS.AES.decrypt(user.keys[i], this.$secretKey).toString(this.CryptoJS.enc.Utf8))
        }
          canDo = planKeys.includes(decriptedKey)
      }

      return canDo
    }
  }
}

export default serviceCan;
