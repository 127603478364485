<template>
  <div>
    <laravue-datatable
      titulo="Perfis de Acesso"
      rota="roles"
      :colunas = "colunas"
      :ordenacao = "ordenacao">
      <template #actions>
        <router-link v-if="$can('editar permissions roles')" to="/paginas/rolesPermission">
          <p-button type="info" >
            <i class="nc-icon nc-key-25"></i>&nbsp; Permissões
          </p-button>
        </router-link>
      </template>
    </laravue-datatable>
  </div>
</template>

<script>
import LaravueDatatable from "@/components/ProjetoBase/Views/Components/LaravueDataTable"

export default {
  components: { LaravueDatatable },
  data() {
    return {
      colunas: [
        {
          prop: "name",
          label: "Perfil de Acesso",
          minWidth: 290,
        },
      ],
      ordenacao:[
        {
          prop: "name",
          order: "ASC",
          default: true
        }
      ]
    }
  }
}
</script>
