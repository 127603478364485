<template>
  <div class="row">
    <div class="col-sm-12">
      <card>
        <div class="row">
          <div class="col-sm-9">
              <h4 slot="header" class="card-title">{{ titulo }}</h4>
          </div>
          <div class="col-sm-2" style="text-align:right">
            <small>{{ loadingMessage }} </small>
          </div>
          <div class="col-sm-1">
              <pulse-loader :loading="loading" color="black" size="5px"></pulse-loader>
          </div>
        </div>
        <ValidationObserver ref="form">
          <form @submit.prevent="submit">
            <div class="row formSpace"> 
				<div class="col-sm-6">
					<ValidationProvider name="Nome" rules="required" v-slot="{ errors }">
						<div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Nome</div>
						<el-select
							:disabled="edicao"
							filterable
							allow-create
							class="baseSelect"
							style="width: 100%;"
							size="large"
							:placeholder="relatorio ? 'Não filtrar' : 'Digite Nome'"
							v-model="model.user_id" >
								<el-option v-if="relatorio" label="Não filtrar" value=""></el-option>
								<el-option
									v-for="item in selects.users"
									class="select-danger"
									style="width: 100%;"
									:value="item.id"
									:label="item.name"
									:key="item.id" >
								</el-option>
						</el-select>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-6">
					<ValidationProvider name="E-mail" rules="required" v-slot="{ errors }">
						<fg-input 
							:disabled="edicao"
							:placeholder="relatorio ? 'Não filtrar' :'Digite e-mail'" 
							label="Email"
							v-model="model.user_email">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
			</div>
			<div class="row formSpace">
				<div class="col-sm-6">
					<ValidationProvider name="Data Admissão" rules="required" v-slot="{ errors }">
						<div v-if="!relatorio" style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Data Admissão</div>
						<div v-else style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Período da Data Admissão</div>
						<el-date-picker
							v-if="!relatorio"
							v-model="model.data_admissao"
							style="width: 100%;"
							type="date"
							format="dd/MM/yyyy"
							value-format="yyyy-MM-dd"
							:placeholder="relatorio ? 'Não filtrar' : 'Selecione data'">
						</el-date-picker>
						<el-date-picker
							v-else
							v-model="model.data_admissao_date_range"
							style="width: 100%;"
							type="daterange"
							range-separator=" à "
							format="dd/MM/yyyy"
							value-format="yyyy-MM-dd"
							start-placeholder="Data inicial"
							end-placeholder="Data final">
						</el-date-picker>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-6">
					<ValidationProvider name="Modalidade" rules="" v-slot="{ errors }">
						<div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Modalidades</div>
						<el-select
							multiple
							filterable
							class="baseSelect"
							size="large"
							style="width: 100%;"
							:placeholder="relatorio ? 'Não filtrar' : 'Modalidades'"
							v-model="model.modalidade_ids" >
								<el-option v-if="relatorio" label="Não filtrar" value=""></el-option>
								<el-option
									v-for="item in selects.modalidades"
									class="select-danger"
									:value="item.id"
									:label="item.nome"
									:key="item.id" >
								</el-option>
						</el-select>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
			</div>
			<div class="row formSpace">
				<div class="col-sm-4">
					<ValidationProvider name="Data Nascimento" rules="required" v-slot="{ errors }">
						<div v-if="!relatorio" style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Data Nascimento</div>
						<div v-else style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Período da Data Nascimento</div>
						<el-date-picker
							v-if="!relatorio"
							v-model="model.data_nascimento"
							style="width: 100%;"
							type="date"
							format="dd/MM/yyyy"
							value-format="yyyy-MM-dd"
							:placeholder="relatorio ? 'Não filtrar' : 'Selecione data'">
						</el-date-picker>
						<el-date-picker
							v-else
							v-model="model.data_nascimento_date_range"
							style="width: 100%;"
							type="daterange"
							range-separator=" à "
							format="dd/MM/yyyy"
							value-format="yyyy-MM-dd"
							start-placeholder="Data inicial"
							end-placeholder="Data final">
						</el-date-picker>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-4">
					<ValidationProvider name="Cpf" rules="required" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite CPF'" 
							label="CPF"
							v-model="model.cpf">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-4">
					<ValidationProvider name="Rg" rules="required" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite RG'" 
							label="RG"
							v-model="model.rg">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
			</div>
			<div class="row formSpace">
				<div class="col-sm-6">
					<ValidationProvider name="Pa" rules="" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite P.A.'" 
							label="P.A."
							v-model="model.pa">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-6">
					<ValidationProvider name="Patologias" rules="" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Patologias'" 
							label="Patologias"
							v-model="model.patologias">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
			</div>
			<div class="row formSpace">
				<div class="col-sm-6">
					<ValidationProvider name="Telefone 1" rules="" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Telefone'" 
							label="Telefone 1"
							v-model="model.telefone1">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-6">
					<ValidationProvider name="Telefone 2" rules="" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Telefone'" 
							label="Telefone 2"
							v-model="model.telefone2">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
			</div>
			<div class="row formSpace">
				<div class="col-sm-6">
					<ValidationProvider name="Nome do Pai" rules="required" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Nome Pai'" 
							label="Nome do Pai"
							v-model="model.nome_pai">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-6">
					<ValidationProvider name="Nome da Mãe" rules="required" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Nome Mae'" 
							label="Nome da Mãe"
							v-model="model.nome_mae">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
			</div>
			<div class="row formSpace">
				<div class="col-sm-2">
					<ValidationProvider name="Cep" rules="required" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Cep'" 
							label="CEP"
							v-model="model.cep">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-6">
					<ValidationProvider name="Endereço" rules="required" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Endereço'" 
							label="Endereço"
							v-model="model.logradouro">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-2">
					<ValidationProvider name="Número'" rules="required" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Número'" 
							label="Número"
							v-model="model.num_logradouro">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-2">
					<ValidationProvider name="Complemento" rules="" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Complemento'" 
							label="Complemento"
							v-model="model.complemento_logradouro">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
			</div>
			<div class="row formSpace">
				<div class="col-sm-4">
					<ValidationProvider name="Bairro" rules="required" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Bairro'" 
							label="Bairro"
							v-model="model.bairro">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-4">
					<ValidationProvider name="Cidade" rules="required" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Cidade'" 
							label="Cidade"
							v-model="model.cidade">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-4">
					<ValidationProvider name="Estado" rules="required" v-slot="{ errors }">
						<fg-input 
							:placeholder="relatorio ? 'Não filtrar' :'Digite Estado'" 
							label="Estado"
							v-model="model.estado">
						</fg-input>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
			</div>
			<div v-if="!relatorio" class="row formSpace">
				<div class="col-sm-12">
					<ValidationProvider name="Enviar E-mail" rules="required" v-slot="{ errors }">
					<div style="margin-bottom: 5px; color: #9A9A9A; font-size: .9971em;">&nbsp;⠀⠀</div>
					<p-checkbox v-model="model.send_email">Enviar e-mail de notificação</p-checkbox>
					<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
				</ValidationProvider>
				</div>
			</div>
			<!-- {{ laravue-insert:field }} -->
            <br>
            <router-link :to="goBack">
              <p-button type="info" round>voltar</p-button>
            </router-link>
            <p-button style="margin-left:8px" v-if="relatorio" type="danger" round @click="getReport('pdf')">PDF</p-button>
            <p-button style="margin-left:8px" v-if="relatorio" type="success" round @click="getReport('xlsx')">XLSX</p-button>
            <p-button style="margin-left:8px" v-else type="success" round nativeType="submit">{{ labelSubmit }}</p-button>
          </form>
        </ValidationObserver>
      </card>
    </div>
  </div>
</template>

<script>
import laravueNotify from "@/components/ProjetoBase/Views/Components/notifications/laravueNotifications"
import PulseLoader from "vue-spinner/src/PulseLoader"
import { DatePicker } from "element-ui"

export default {
  components: { PulseLoader, [DatePicker.name]: DatePicker },
  props: {
    titulo: String,
    edicao: Boolean,
    relatorio: Boolean,
    rotaVoltar: String,
    rota: String
  },
  data() {
    return {
      backRoute: '',
      loadingMessage: "",
      loading: false,
      selects: { 
		users: [],
        modalidades: [],
		// {{ laravue-insert:dataSelects }}
      },
      model: {
        user_id: '',
        user_email: '',
		data_admissao: '',data_admissao_date_range: '',
		data_nascimento: '',data_nascimento_date_range: '',
		cpf: '',
		rg: '',
		pa: '',
		patologias: '',
		telefone1: '',
		telefone2: '',
		nome_pai: '',
		nome_mae: '',
		logradouro: '',
		num_logradouro: '',
		complemento_logradouro: '',
		bairro: '',
		cidade: 'Santa Luzia',
		estado: 'MG',
		cep: '',
		send_email: false,
        modalidade_ids: [],
		// {{ laravue-insert:dataModel }}
      }
    }
  },
  mounted() {
    this.backRoute = this.$route.query.page ? `${this.rotaVoltar}?page=${this.$route.query.page}` : this.rotaVoltar
  },
  created() {
    this.loadSelects()
	if( this.edicao ) {
		this.model.send_email = true
	}
  },
  computed: {
    labelSubmit() {
      return this.edicao ? `Alterar` : `Cadastrar`
    },
    goBack() {
      return `/paginas/${this.backRoute}`
    },
    url() {
      return this.edicao
        ? `${this.rota}/${this.$route.params.modelId}`
        : this.rota
    },

    // {{ laravue-insert:computed }}
  },
  methods: {
    setLoading(status, message = "") {
      if(this.loading != status){
        this.loading = status
      }
      this.loadingMessage = message
    },
    getReportFilter() {
      return `?model_filter=${JSON.stringify(this.model)}`
    },
    getReport(modelo) {
      this.setLoading(true, "Gerando Arquivo")
      this.$http
        .get(`reports/${this.rota}/${modelo}${this.getReportFilter()}`, {responseType: 'arraybuffer'})
        .then(response => {
          this.setLoading(false)
          this.$getReport(response, modelo)
        })
        .catch(e => {
          this.setLoading(false, "Não foi possível gerar arquivo")
          laravueNotify.failure(this, e)
        })
    },
    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        let message = this.edicao ? "Alterando" : "Inserindo"
        this.setLoading(true, message)
		if( !Number.isInteger(this.model.user_id) ) {
			this.model.user_name = this.model.user_id
			this.model.user_id = 0
		}
		let send_email = this.model.send_email == 1 ? true : false
        this.$http({
          method: this.edicao ? "put" : "post",
          url: this.url,
          data: { 
			user_id: parseInt( this.model.user_id ),
			user_name: this.model.user_name,
			user_email: this.model.user_email,
			data_admissao: this.model.data_admissao,
			data_nascimento: this.model.data_nascimento,
			cpf: this.model.cpf,
			rg: this.model.rg,
			pa: this.model.pa,
			patologias: this.model.patologias,
			telefone1: this.model.telefone1,
			telefone2: this.model.telefone2,
			nome_pai: this.model.nome_pai,
			nome_mae: this.model.nome_mae,
			logradouro: this.model.logradouro,
			num_logradouro: this.model.num_logradouro,
			complemento_logradouro: this.model.complemento_logradouro,
			bairro: this.model.bairro,
			cidade: this.model.cidade,
			estado: this.model.estado,
			cep: this.model.cep, 
			send_email: send_email,
            modalidade_ids: this.model.modalidade_ids, 
			// {{ laravue-insert:submit }}
          }
        })
          .then( () => {
            if (!this.edicao) {
              this.model = {
                user_id: '',
                user_email: '',
				data_admissao: '',
				data_nascimento: '',
				cpf: '',
				rg: '',
				pa: '',
				patologias: '',
				telefone1: '',
				telefone2: '',
				nome_pai: '',
				nome_mae: '',
				logradouro: '',
				num_logradouro: '',
				complemento_logradouro: '',
				bairro: '',
				cidade: '',
				estado: '',
				cep: '',
				send_email: false
              }
              this.$refs.form.reset()
            } else {
				this.model.send_email = true
			}
            this.setLoading(false)
            if (this.edicao) {
              laravueNotify.success(this, "edit")
            } else {
              laravueNotify.success(this)
            }
          })
          .catch(e => {
            this.setLoading(false)
            laravueNotify.failure(this, e)
          })
      })
    },
    loadModel(){
      this.loadModalidades()
	  // {{ laravue-insert:loadModelMethod }}
      if (this.edicao) {
        this.setLoading(true, "Aluno")
        this.$http
          .get(this.url)
          .then(response => {
            this.setLoading(false)
            this.model = response.data.data
			this.model.send_email = false
			this.model.user_email = this.model.user.email
            this.model.modalidade_ids = []
			this.model.modalidades.forEach(element => {
				this.model.modalidade_ids.push( element.id )
			})
			// {{ laravue-insert:loadModelResponse }} 
          })
          .catch(e => {
            laravueNotify.failure(this, e)
          })
      } else {
        this.setLoading(false)
      }
    },
    loadSelects() {
			this.setLoading(true, "carregando")
			this.loadUsers() // cascade calls
	},
	loadUsers() {
		this.setLoading(true, "User")
		this.$http
			.get(`users?per_page=-1&order_by=name`)
			.then(response => {
				this.setLoading(false)
				this.selects.users = response.data.data.data
				this.loadModel()
			})
			.catch(e => {
				this.setLoading(false)
				laravueNotify.failure(this, e)
			})
	}, 
    loadModalidades() {
		return this.$http
			.get(`modalidades?per_page=-1&order_by=nome&active=1`)
			.then(response => {
				this.selects.modalidades = response.data.data.data
		})
		.catch(e => {
			laravueNotify.failure(this, e)
		})
	},
	// {{ laravue-insert:methods }}
  }
}
</script>

<style scoped>
.formSpace {
  margin-bottom: 8px;
}
</style>
