<template>
  <div>
    <laravue-datatable
      titulo="Modalidades"
      rota="modalidades"
      :colunas = "colunas"
      :ordenacao = "ordenacao"
      overrideShow 
      overrideDelete >
        <template v-slot:modalShow="{ model }">
            <show :model="model"></show>
        </template>
        <template v-slot:modalDelete="{ model }">
          <delete :model="model"></delete>
        </template>
    </laravue-datatable>
  </div>
</template>

<script>
import LaravueDatatable from "@/components/ProjetoBase/Views/Components/LaravueDataTable"
import Show from "./Show"
import Delete from "./Delete"

export default {
  name: "Index.vue",
  components: { LaravueDatatable, Show, Delete },
  data() {
    return {
      colunas: [
        {
					prop: "nome",
					label: "Nome",
					minWidth: 165,
				},
				{
					prop: "ativo",
					label: "Ativo",
					minWidth: 165,
					type: "bit",
				},
      ],
      ordenacao:[
        {
          prop: "id",
          order: "ASC",
          default: true
        }
      ]
    }
  }
}
</script>