<template>
  <div>
    <laravue-datatable
      titulo="Versões/Novidades"
      rota="tasks"
      :colunas = "colunas"
      :ordenacao = "ordenacao">
    </laravue-datatable>
  </div>
</template>

<script>
import LaravueDatatable from "@/components/ProjetoBase/Views/Components/LaravueDataTable"

export default {
  components: { LaravueDatatable },
  data() {
    return {
      colunas: [
        {
          prop: "id",
          label: "ID",
          minWidth: 25,
        },
        {
          prop: "version",
          label: "Versão",
          minWidth: 35,
        },
        {
          prop: "module",
          label: "Módulo",
          minWidth: 45,
        },
        {
          prop: "name_with_group_name",
          label: "Tarefa",
          minWidth: 135,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 50,
        }
      ],
      ordenacao:[
        {
          prop: "id",
          order: "ASC",
          default: true
        }
      ]
    }
  }
}
</script>
