<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">{{title}}</h4>
    </div>
    <div class="card-body">
      <ul class="list-unstyled team-permissions">
        <li>
          <div class="row" v-for="(member, index) in permissions" :key="index">
            <div class="col-md-9 col-9">
              {{member.name}}
              <br>
              <span :class="getStatusClass(member.status)">
                <small>{{member.status}}</small>
              </span>
            </div>

            <div class="col-md-3 col-3 text-right">
              <p-button size="sm" type="warning" outline icon round>
                <i class="fa fa-edit"></i>
              </p-button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import {Button} from 'src/components/UIComponents'
  export default {
    components: {
      [Button.name]: Button
    },
    data () {
      return {
        title: 'Permissões',
        permissions: [
          {
            name: 'Admin',
            status: 'revogado'
          },
          {
            name: 'Desenvolvedor',
            status: 'concedido'
          },
          {
            name: 'Acesso Padrão',
            status: 'concedido'
          }
        ]
      }
    },
    methods: {
      getStatusClass (status) {
        switch (status) {
          case 'revogado':
            return 'text-muted'
          case 'concedido':
            return 'text-success'
          default:
            return 'text-success'
        }
      }
    }
  }

</script>
<style>

</style>
