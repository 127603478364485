<template>
  <div>
    <laravue-datatable
      titulo="Dobras Cutâneas"
      rota="dobracutaneas"
      :colunas = "colunas"
      :ordenacao = "ordenacao"
      overrideShow 
      overrideDelete >
        <template v-slot:modalShow="{ model }">
            <show :model="model"></show>
        </template>
        <template v-slot:modalDelete="{ model }">
          <delete :model="model"></delete>
        </template>
    </laravue-datatable>
  </div>
</template>

<script>
import LaravueDatatable from "@/components/ProjetoBase/Views/Components/LaravueDataTable"
import Show from "./Show"
import Delete from "./Delete"

export default {
  name: "Index.vue",
  components: { LaravueDatatable, Show, Delete },
  data() {
    return {
      colunas: [
        {
			prop: "aluno.user.name",
			label: "Aluno",
			minWidth: 280,
		},
		{
			prop: "data",
			label: "Data",
			minWidth: 50,
			type:"date",
		},
      ],
      ordenacao:[
        {
          prop: "id",
          order: "ASC",
          default: true
        }
      ]
    }
  }
}
</script>