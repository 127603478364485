<template>
  <div v-if="model">
    <div class="row">
		<div class="col-sm-12">
			<p>
				<b>Aluno</b>
				<br/>
				{{ model.aluno.user.name }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Data</b>
				<br/>
				{{ model.data }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Peso</b>
				<br/>
				{{ model.peso }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Ombro</b>
				<br/>
				{{ model.ombro }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Peitoral</b>
				<br/>
				{{ model.peitoral }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Braço Direito</b>
				<br/>
				{{ model.braco_direito }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Antebraço Direito</b>
				<br/>
				{{ model.antebraco_direito }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Braço Esquerdo</b>
				<br/>
				{{ model.braco_esquerdo }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Antebraço Esquerdo</b>
				<br/>
				{{ model.antebraco_esquerdo }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Cintura</b>
				<br/>
				{{ model.cintura }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Abdômen</b>
				<br/>
				{{ model.abdomen }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Quadril</b>
				<br/>
				{{ model.quadril }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Coxa Direita</b>
				<br/>
				{{ model.coxa_direita }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Perna Direita</b>
				<br/>
				{{ model.perna_direita }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Coxa Esquerda</b>
				<br/>
				{{ model.coxa_esquerda }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Perna Esquerda</b>
				<br/>
				{{ model.perna_esquerda }}
			</p>
		</div>
	</div>
    <!-- {{ laravue-insert:field }} -->
  </div>
</template>

<script>
export default {
  name: "Show",
  props: {
    model: undefined
  }
}
</script>