
import Vue from 'vue'
import {  ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
// Localization
import pt_BR from "vee-validate/dist/locale/pt_BR"
localize( 'pt_BR', pt_BR );
// VeeValidate Rules
import * as rules from 'vee-validate/dist/rules';
for (let [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation
  });
}
// ExtendedRules
extend('cnpj', {
  validate: value => value.replace(/[^0-9]+/g,'').length == 14,
  message: 'o campo {_field_} precisa ter 14 dígitos.'
})

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);