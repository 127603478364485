var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.titulo))])]),_c('div',{staticClass:"col-sm-2",staticStyle:{"text-align":"right"}},[_c('small',[_vm._v(_vm._s(_vm.loadingMessage)+" ")])]),_c('div',{staticClass:"col-sm-1"},[_c('pulse-loader',{attrs:{"loading":_vm.loading,"color":"black","size":"5px"}})],1)]),_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Tipo")]),_c('el-select',{staticClass:"baseSelect",attrs:{"filterable":"","label":"Grupo","size":"large","placeholder":_vm.relatorio ? 'Não filtrar' : 'Tipo'},model:{value:(_vm.model.tipo),callback:function ($$v) {_vm.$set(_vm.model, "tipo", $$v)},expression:"model.tipo"}},[(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não filtrar","value":""}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Debug","value":"DEBUG"}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Informação","value":"INFO"}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Erro","value":"ERRO"}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Aviso","value":"AVISO"}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Crítico","value":"CRITICO"}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Emergencial","value":"EMERGENCIAL"}}):_vm._e()],1),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Resultado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Resultado")]),_c('el-select',{staticClass:"baseSelect",attrs:{"filterable":"","label":"Status","size":"large","placeholder":_vm.relatorio ? 'Não filtrar' :'Resultado'},model:{value:(_vm.model.resultado),callback:function ($$v) {_vm.$set(_vm.model, "resultado", $$v)},expression:"model.resultado"}},[(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não filtrar","value":""}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Sucesso","value":"SUCESSO"}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Neutro","value":"NEUTRO"}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Falha","value":"FALHA"}}):_vm._e()],1),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Tarefa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Tarefa',"label":"Mensagem"},model:{value:(_vm.model.mensagem),callback:function ($$v) {_vm.$set(_vm.model, "mensagem", $$v)},expression:"model.mensagem"}}),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Data")]),_c('el-date-picker',{attrs:{"type":"date","format":"dd/MM/yyyy","value-format":"yyyy-MM-dd","placeholder":_vm.relatorio ? 'Não filtrar' :'Selecione data'},model:{value:(_vm.model.created_at),callback:function ($$v) {_vm.$set(_vm.model, "created_at", $$v)},expression:"model.created_at"}}),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('br'),_c('router-link',{attrs:{"to":_vm.goBack}},[_c('p-button',{attrs:{"type":"info","round":""}},[_vm._v("voltar")])],1),(_vm.relatorio)?_c('p-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"danger","round":""},on:{"click":function($event){return _vm.getReport('pdf')}}},[_vm._v("PDF")]):_vm._e(),(_vm.relatorio)?_c('p-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"success","round":""},on:{"click":function($event){return _vm.getReport('xlsx')}}},[_vm._v("XLSX")]):_vm._e()],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }