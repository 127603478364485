<template>
  <div class="row">
    <div class="col-sm-12">
      <card>
        <div class="row">
          <div class="col-sm-9">
              <h4 slot="header" class="card-title">{{ titulo }}</h4>
          </div>
          <div class="col-sm-2" style="text-align:right">
            <small>{{ loadingMessage }} </small>
          </div>
          <div class="col-sm-1">
              <pulse-loader :loading="loading" color="black" size="5px"></pulse-loader>
          </div>
        </div>
        <ValidationObserver ref="form">
          <form @submit.prevent="submit">
            <div class="row formSpace">
							<div class="col-sm-12">
								<ValidationProvider name="Nome" rules="required" v-slot="{ errors }">
									<fg-input 
										:placeholder="relatorio ? 'Não filtrar' :'Digite Nome'" 
										label="Nome"
										v-model="model.nome">
									</fg-input>
									<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
								</ValidationProvider>
							</div>
						</div>
						<div class="row formSpace">
							<div v-if="relatorio" class="col-sm-12">
								<ValidationProvider name="Ativo" rules="required" v-slot="{ errors }">
									<div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Ativo</div>
									<el-select  v-model="model.ativo">
										<el-option label="Não filtrar" value=""></el-option>
										<el-option label="Sim" value="true"></el-option>
										<el-option label="Não" value="false"></el-option>
									</el-select>
									<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
								</ValidationProvider>
							</div>
								<div v-else class="col-sm-12">
									<ValidationProvider name="Ativo" rules="required" v-slot="{ errors }">
									<div style="margin-bottom: 5px; color: #9A9A9A; font-size: .9971em;">&nbsp;⠀⠀</div>
									<p-checkbox v-model="ativo">Ativo</p-checkbox>
									<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
								</ValidationProvider>
							</div>
						</div>
						<!-- {{ laravue-insert:field }} -->
            <br>
            <router-link :to="goBack">
              <p-button type="info" round>voltar</p-button>
            </router-link>
            <p-button style="margin-left:8px" v-if="relatorio" type="danger" round @click="getReport('pdf')">PDF</p-button>
            <p-button style="margin-left:8px" v-if="relatorio" type="success" round @click="getReport('xlsx')">XLSX</p-button>
            <p-button style="margin-left:8px" v-else type="success" round nativeType="submit">{{ labelSubmit }}</p-button>
          </form>
        </ValidationObserver>
      </card>
    </div>
  </div>
</template>

<script>
import laravueNotify from "@/components/ProjetoBase/Views/Components/notifications/laravueNotifications"
import PulseLoader from "vue-spinner/src/PulseLoader"
import { DatePicker } from "element-ui"

export default {
  components: { PulseLoader, [DatePicker.name]: DatePicker },
  props: {
    titulo: String,
    edicao: Boolean,
    relatorio: Boolean,
    rotaVoltar: String,
    rota: String
  },
  data() {
    return {
      backRoute: '',
      loadingMessage: "",
      loading: false,
      selects: { 
        // {{ laravue-insert:dataSelects }}
      },
      model: {
        nome: '',
				ativo: this.relatorio ? '' : false,
        // {{ laravue-insert:dataModel }}
      }
    }
  },
  mounted() {
    this.backRoute = this.$route.query.page ? `${this.rotaVoltar}?page=${this.$route.query.page}` : this.rotaVoltar
  },
  created() {
    this.loadSelects()
  },
  computed: {
    labelSubmit() {
      return this.edicao ? `Alterar` : `Cadastrar`
    },
    goBack() {
      return `/paginas/${this.backRoute}`
    },
    url() {
      return this.edicao
        ? `${this.rota}/${this.$route.params.modelId}`
        : this.rota
    },
		ativo: {
			get: function() {
				return this.model.ativo >= 1 ? true : false;
			},
			set: function(newValue) {
				this.model.ativo = newValue ? 1 : 0;
			},
		},
    // {{ laravue-insert:computed }}
  },
  methods: {
    setLoading(status, message = "") {
      if(this.loading != status){
        this.loading = status
      }
      this.loadingMessage = message
    },
    getReportFilter() {
      return `?model_filter=${JSON.stringify(this.model)}`
    },
    getReport(modelo) {
      this.setLoading(true, "Gerando Arquivo")
      this.$http
        .get(`reports/${this.rota}/${modelo}${this.getReportFilter()}`, {responseType: 'arraybuffer'})
        .then(response => {
          this.setLoading(false)
          this.$getReport(response, modelo)
        })
        .catch(e => {
          this.setLoading(false, "Não foi possível gerar arquivo")
          laravueNotify.failure(this, e)
        })
    },
    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        let message = this.edicao ? "Alterando" : "Inserindo"
        this.setLoading(true, message)
        this.$http({
          method: this.edicao ? "put" : "post",
          url: this.url,
          data: { 
						nome: this.model.nome,
						ativo: this.model.ativo, 
            // {{ laravue-insert:submit }}
          }
        })
          .then( () => {
            if (!this.edicao) {
              this.model = {
                nome: '',
								ativo: this.relatorio ? '' : false,
              }
              this.$refs.form.reset()
            }
            this.setLoading(false)
            if (this.edicao) {
              laravueNotify.success(this, "edit")
            } else {
              laravueNotify.success(this)
            }
          })
          .catch(e => {
            this.setLoading(false)
            laravueNotify.failure(this, e)
          })
      })
    },
    loadModel(){
      // {{ laravue-insert:loadModelMethod }}
      if (this.edicao) {
        this.setLoading(true, "Modalidade")
        this.$http
          .get(this.url)
          .then(response => {
            this.setLoading(false)
            this.model = response.data.data
            // {{ laravue-insert:loadModelResponse }} 
          })
          .catch(e => {
            laravueNotify.failure(this, e)
          })
      } else {
        this.setLoading(false)
      }
    },
    loadSelects() {
			this.setLoading(true, "carregando")
			this.loadModel() // cascade calls
		},

    // {{ laravue-insert:methods }}
  }
}
</script>

<style scoped>
.formSpace {
  margin-bottom: 8px;
}
</style>
