<template>
  <model-form
    titulo="Edição de Permissões"
    rota="permissions"
    :rotaVoltar="rotaVoltar"
    edicao
  ></model-form>
</template>

<script>
import ModelForm from "./forms/Model"

export default {
  components: { ModelForm },
  data: () => ({
    rotaVoltar: `configuration/security/permission`
  }),
  mounted() {
    this.rotaVoltar = this.$route.query.page ? `${this.rotaVoltar}?page=${this.$route.query.page}` : this.rotaVoltar
  }
}
</script>