<template>
  <div>
    <div  class="row">
      <div class="col-md-4">
        <form>
      <input
            type="text"
            value="" class="form-control" placeholder="Selecione a imagem"
            @click='pickFile' v-model='imageName'>

        <div class="input-group no-border">
            <input
            type="file"
            style="display: none"
            ref="image"
            accept="image/*"
            @change="onFilePicked">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-image"></i>
            </div>
          </div>
        </div>
      </form>
      </div>
      <div class="col-md-4">

      </div>
      <div class="col-md-4">

      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <img :src="imageUrl" height="150" v-if="imageUrl"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ImagePicker',
    data: () => ({
		imageName: '',
		imageUrl: '',
		imageFile: ''
    }),

    methods: {
		imageUpload(e){
            let local_file = e.target.files || e.dataTransfer.files
            if(!local_file.length){
                return
            }

            let reader = new FileReader()
            reader.onloadend = (e) => {
				    //this.image = e.target.result
				    // this.$store.commit('setFile', e.target.result)
            }
            reader.readAsDataURL(local_file[0])

        },
        pickFile () {
            this.$refs.image.click ()
        },

		onFilePicked (e) {
			let local_file = e.target.files || e.dataTransfer.files
            if(!local_file.length){
                this.imageName = ''
				this.imageFile = ''
				this.imageUrl = ''
                return
            }

            let reader = new FileReader()
            reader.onloadend = (e) => {
                //this.image = e.target.result
                this.imageName = local_file[0].name
                this.imageUrl = reader.result
                this.imageFile = local_file[0]
                // this.$store.commit('setFile', e.target.result)
                this.$emit('fileLoaded', e.target.result)
            }
            reader.readAsDataURL(local_file[0])


			// const files = e.target.files
			// if(files[0] !== undefined) {
			// 	this.imageName = files[0].name
			// 	if(this.imageName.lastIndexOf('.') <= 0) {
			// 		return
			// 	}
			// 	const fr = new FileReader ()
			// 	fr.readAsDataURL(files[0])
			// 	fr.addEventListener('load', () => {
			// 		this.imageUrl = fr.result
			// 		this.imageFile = files[0] // this is an image file that can be sent to server...
			// 		//this.$store.commit('setFile', this.imageFile)
			// 	})
			// } else {
			// 	this.imageName = ''
			// 	this.imageFile = ''
			// 	this.imageUrl = ''
			// }
		}
    }

}
</script>

<style>

</style>
