<template>
  <div>
    <laravue-datatable
      titulo="Alunos"
      rota="alunos"
      :colunas = "colunas"
      :ordenacao = "ordenacao"
      overrideShow 
      overrideDelete >
        <template v-slot:modalShow="{ model }">
            <show :model="model"></show>
        </template>
        <template v-slot:modalDelete="{ model }">
          <delete :model="model"></delete>
        </template>
    </laravue-datatable>
  </div>
</template>

<script>
import LaravueDatatable from "@/components/ProjetoBase/Views/Components/LaravueDataTable"
import Show from "./Show"
import Delete from "./Delete"

export default {
  name: "Index.vue",
  components: { LaravueDatatable, Show, Delete },
  data() {
    return {
      colunas: [
        {
          prop: "user.name",
          label: "Nome",
          minWidth: 100,
        },
        {
          prop: "data_admissao",
          label: "Admissão",
          minWidth: 50,
          type: 'date',
        },
        {
          prop: "data_nascimento",
          label: "Nascimento",
          minWidth: 60,
          type: 'date',
        },
        {
          prop: "cpf",
          label: "CPF",
          minWidth: 60,
          type: 'cpf'
        },
        {
          prop: "telefone1",
          label: "Telefone",
          minWidth: 60,
          type: 'telefone'
        },
      ],
      ordenacao:[
        {
          prop: "user.name",
          order: "ASC",
          default: true
        }
      ]
    }
  }
}
</script>