<template>

    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <info-section class="mt-5"
                            type="danger"
                            title="Marketing"
                            description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                            icon="nc-icon nc-alert-circle-i">
                <h2 class="text-white mt-3">Página não encontrada</h2>
                <small class="text-white">Oops! A página solicitada não existe.
                  <br>
                  Você pode voltar para <router-link to="/">a página principal</router-link>
                </small>
              </info-section>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" :style="sectionImage(background)">
        </div>
      </div>
    </div>

</template>
<script>
  import {InfoSection} from 'src/components/UIComponents';
  import AppFooter from './../ProjetoBase/Views/Pages/Layout/AppFooter'
  export default {
    components: {
      AppFooter,
      InfoSection,
    },
    props: {
      background: {
      type: String,
      default: require("@/assets/img/background/jan-sendereks.jpg")
    }
  },
    methods: {
      sectionImage(image) {
        return {
          backgroundImage: `url(${image})`
        }
      }
    }
  }
</script>
<style>
</style>
