<template>
  <div>
    <h4 slot="header" class="card-title" style="margin-bottom:32px">Segurança</h4>

    <el-tabs v-model="activeTab" type="border-card">

    <el-tab-pane v-if="$can('ver users')" name="user">
        <span slot="label"><i class="el-icon-user"></i> Usuários</span>
          <el-tabs v-model="activeUserTab" type="border-card">

            <el-tab-pane name="userList">
            <span slot="label"><i class="el-icon-notebook-2"></i> Listagem</span>
                <user></user>
            </el-tab-pane>

            <el-tab-pane v-if="$can('editar roles users')" name="userRole">
              <span slot="label"><i class="nc-icon nc-key-25"></i> Perfis de Acesso do Usuário</span>
              <user-role></user-role>
          </el-tab-pane>

          <el-tab-pane v-if="$can('editar permissions users')" name="userPermission">
              <span slot="label"><i class="el-icon-guide"></i> Permissões Especiais do Usuário</span>
              <user-permission></user-permission>
          </el-tab-pane>

        </el-tabs>
    </el-tab-pane>
    <el-tab-pane v-if="$can('ver roles')" name="role">

        <span slot="label"><i class="el-icon-connection"></i> Perfis de Acesso</span>
        <el-tabs v-model="activeRoleTab" type="border-card">

            <el-tab-pane name="roleList">
            <span slot="label"><i class="el-icon-notebook-2"></i> Listagem</span>
                <role></role>
            </el-tab-pane>

            <el-tab-pane v-if="$can('editar permissions roles')" name="rolePermission">
              <span slot="label"><i class="nc-icon nc-key-25"></i> Permissões do Perfil de Acesso</span>
              <role-permission></role-permission>
          </el-tab-pane>

        </el-tabs>
        
    </el-tab-pane>
    <el-tab-pane v-if="$can('ver permissions')" name="permission">
        <span slot="label"><i class="el-icon-open"></i> Permissões</span>
        <permission></permission>
    </el-tab-pane>

  </el-tabs>
  </div>
</template>

<script>
import User from 'src/components/ProjetoBase/Views/Pages/User/Configuration.vue'
import UserRole from 'src/components/ProjetoBase/Views/Pages/User/Role.vue'
import UserPermission from 'src/components/ProjetoBase/Views/Pages/User/Permission.vue'
import Role from 'src/components/ProjetoBase/Views/Pages/Role/Configuration.vue'
import RolePermission from 'src/components/ProjetoBase/Views/Pages/Role/Permission.vue'
import Permission from 'src/components/ProjetoBase/Views/Pages/Permission/Configuration.vue'

export default {
    components: { User, Role, Permission, UserRole, UserPermission, RolePermission },
    data() {
      return {
        activeTab: String,
        activeUserTab: 'userList',
        activeRoleTab: 'roleList'
      }
    },
    created() {
      this.activeTab = this.$route.params.tab
    }
}
</script>

<style>

</style>