export default [
	// {{ laravue-insert:routes }}
	{
		name: 'Painel de Controle',
		icon: 'nc-icon nc-layout-11',
		path: '/paginas/painelControle',
		permission: 'ver menu painel de controle'
	},
	{
		name: 'Alunos',
		icon: 'nc-icon nc-single-02', 
		path: '/paginas/alunos',
		permission: 'ver alunos',
		//children: [
		//	{
		//		name: '',
		//		path: '',
		//		permission: '',
		//	},
		//],
	},
	{
		name: 'Avaliações Físicas',
		icon: 'nc-icon nc-paper', 
		path: '/paginas/avaliacaofisicas',
		permission: 'ver avaliacaofisicas',
	},
	{
		name: 'Dobras Cutâneas',
		icon: 'nc-icon nc-paper', 
		path: '/paginas/dobracutaneas',
		permission: 'ver dobracutaneas',
	},
]
