import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
import VueRouterPrefetch from 'vue-router-prefetch'
import VueNotify from 'vue-notifyjs'
import App from './App.vue'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar';
import VueJsonp from 'vue-jsonp';

// Services
import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)
import token from './services/loadToken'
import getReport from './services/getReport'
import captalize from './services/capitalize'
import formatNumberToDatabase from './services/formatNumberToDatabase'
import can from './services/can'
Vue.use(token)
Vue.use(getReport)
Vue.use(captalize)
Vue.use(formatNumberToDatabase)
Vue.use(can)

// router setup
import routes from './routes/routes'
const apiEndpoint = process.env.VUE_APP_API_ENDPOINT

// vuex setup
import Vuex from 'vuex'

// axios setup
import axios from 'axios'
if(localStorage.user){
  let access_token = JSON.parse(localStorage.user).access_token
  axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
}
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_BACKEND + apiEndpoint
Vue.prototype.$http = axios

// ElementUI setup
import ElementUI from 'element-ui'
import '@/assets/sass/element-variables.scss' // Default is at 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/pt-br'
import locale from 'element-ui/lib/locale'
Vue.use(ElementUI, { locale })

// Máscaras nos campos
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

// Máscaras nos campos dinheiro e percentual
import money from 'v-money'
const moneyConfig = {
  decimal: ',',
  thousands: '.',
  prefix: '',
  suffix: '',
  precision: 2,
  masked: false
}
// register directive v-money and component <money>
Vue.use(money)
Vue.prototype.$money = moneyConfig

// VeeValidate 3.x setup
import './laravueSetup/veeValidate'

// library imports
import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'

import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'
// plugin setup
Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(VueJsonp, 5000)
Vue.use(SideBar, {sidebarLinks: sidebarLinks})
Vue.use(Vuex)
locale.use(lang)

// configure router
const router = new VueRouter({
  mode: 'history', // retirar o # dos links
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})

initProgress(router);

import store from './store/index'

// Variáveis de ambiente: arquivo .env
Vue.prototype.$urlHOME = location.protocol + '//' +location.hostname + (location.port ? ':' + location.port : '') + '/'
Vue.prototype.$clientId = process.env.VUE_APP_PASSPORT_CLIENT_ID
Vue.prototype.$clientSecret = process.env.VUE_APP_PASSPORT_CLIENT_SECRET
Vue.prototype.$avatarURL = process.env.VUE_APP_BASE_URL_BACKEND
Vue.prototype.$secretKey = process.env.VUE_APP_SECRET_KEY


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})
