export default {
    setIndexToDelete(state,n){     
        state.indexToDelete = n
    },
    setIdToDelete(state,n){     
        state.idToDelete = n
    },
    setTableData(state,n){
        state.tableData = n
    }
}