<template>
  <model-form
    titulo="Relatório de Usuários"
    rota="users"
    rotaVoltar="configuration/security/user"
    relatorio
  ></model-form>
</template>

<script>
import ModelForm from "./forms/Model"

export default {
  components: { ModelForm },
}
</script>