<template>
  <div>
    <laravue-datatable
      titulo="Monitoramento"
      rota="monitors"
      :colunas = "colunas"
      :ordenacao = "ordenacao"
      overrideShow>
    <template v-slot:modalShow="{ model }">
        <show :model="model"></show>
    </template>
    </laravue-datatable>
  </div>
</template>
<script>
import LaravueDatatable from "@/components/ProjetoBase/Views/Components/LaravueDataTable"
import Show from "./Show"

export default {
  components:  { LaravueDatatable, Show },
  data () {
    return {
      colunas: [
        {
          prop: "id",
          label: "#",
          minWidth: 30
        },
        {
          prop: "type",
          label: "Tipo",
          minWidth: 50
        },
        {
          prop: "message",
          label: "Mensagem",
          minWidth: 120
        },
        {
          prop: "class",
          label: "classe",
          minWidth: 50
        },
        {
          prop: "method",
          label: "Metodo",
          minWidth: 50
        }
      ],
      ordenacao:[
        {
          prop: "id",
          order: "DESC",
          default: true
        }
      ],
    }
  }
}

</script>

