<template>
  <div class="row">
    <div class="col-sm-12">
      <card>
        <div class="row">
          <div class="col-sm-9">
              <h4 slot="header" class="card-title">{{ titulo }}</h4>
          </div>
          <div class="col-sm-2" style="text-align:right">
            <small>{{ loadingMessage }} </small>
          </div>
          <div class="col-sm-1">
              <pulse-loader :loading="loading" color="black" size="5px"></pulse-loader>
          </div>
        </div>
        <ValidationObserver ref="form">
          <form @submit.prevent="submit">
            <div class="row formSpace"> 
				<div class="col-sm-4">
					<ValidationProvider name="Data" rules="required" v-slot="{ errors }">
						<div v-if="!relatorio" style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Data</div>
						<div v-else style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Período da Data</div>
						<el-date-picker
							v-if="!relatorio"
							v-model="model.data"
							style="width: 100%;"
							type="date"
							format="dd/MM/yyyy"
							value-format="yyyy-MM-dd"
							:placeholder="relatorio ? 'Não filtrar' : 'Selecione data'">
						</el-date-picker>
						<el-date-picker
							v-else
							v-model="model.data_date_range"
							style="width: 100%;"
							type="daterange"
							range-separator=" à "
							format="dd/MM/yyyy"
							value-format="yyyy-MM-dd"
							start-placeholder="Data inicial"
							end-placeholder="Data final">
						</el-date-picker>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				<div class="col-sm-8">
					<ValidationProvider name="Aluno" rules="required" v-slot="{ errors }">
						<div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Aluno</div>
						<el-select
							filterable
							class="baseSelect"
							style="width: 100%;"
							size="large"
							:placeholder="relatorio ? 'Não filtrar' : 'Aluno'"
							v-model="model.aluno_id" >
								<el-option v-if="relatorio" label="Não filtrar" value=""></el-option>
								<el-option
									v-for="item in selects.alunos"
									class="select-danger"
									style="width: 100%;"
									:value="item.id"
									:label="item.user.name"
									:key="item.id" >
								</el-option>
						</el-select>
						<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
					</ValidationProvider>
				</div>
				</div>
				<div class="row formSpace">
					<div class="col-sm-4">
						<ValidationProvider name="Costas" rules="required" v-slot="{ errors }">
							<fg-input 
								:placeholder="relatorio ? 'Não filtrar' :'Digite Costas'" 
								label="Costas"
								v-model="model.costas">
							</fg-input>
							<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
						</ValidationProvider>
					</div>
					<div class="col-sm-4">
						<ValidationProvider name="Tríceps" rules="required" v-slot="{ errors }">
							<fg-input 
								:placeholder="relatorio ? 'Não filtrar' :'Digite '" 
								label="Tríceps"
								v-model="model.triceps">
							</fg-input>
							<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
						</ValidationProvider>
					</div>
					<div class="col-sm-4">
						<ValidationProvider name="Bíceps" rules="required" v-slot="{ errors }">
							<fg-input 
								:placeholder="relatorio ? 'Não filtrar' :'Digite Bíceps'" 
								label="Bíceps"
								v-model="model.biceps">
							</fg-input>
							<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
						</ValidationProvider>
					</div>
				</div>
				<div class="row formSpace">
					<div class="col-sm-6">
						<ValidationProvider name="Abdômen Lateral" rules="required" v-slot="{ errors }">
							<fg-input 
								:placeholder="relatorio ? 'Não filtrar' :'Digite Abdômen Lateral'" 
								label="Abdômen Lateral"
								v-model="model.abdomen_lateral">
							</fg-input>
							<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
						</ValidationProvider>
					</div>
					<div class="col-sm-6">
						<ValidationProvider name="Abdômen Frontal" rules="required" v-slot="{ errors }">
							<fg-input 
								:placeholder="relatorio ? 'Não filtrar' :'Digite Abdômen Frontal'" 
								label="Abdômen Frontal"
								v-model="model.abdomen_frontal">
							</fg-input>
							<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
						</ValidationProvider>
					</div>
				</div>
				<div class="row formSpace">
					<div class="col-sm-6">
						<ValidationProvider name="Coxa" rules="required" v-slot="{ errors }">
							<fg-input 
								:placeholder="relatorio ? 'Não filtrar' :'Digite Coxa'" 
								label="Coxa"
								v-model="model.coxa">
							</fg-input>
							<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
						</ValidationProvider>
					</div>
					<div class="col-sm-6">
						<ValidationProvider name="Perna" rules="required" v-slot="{ errors }">
							<fg-input 
								:placeholder="relatorio ? 'Não filtrar' :'Digite Perna'" 
								label="Perna"
								v-model="model.perna">
							</fg-input>
							<div v-if="!relatorio" class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
						</ValidationProvider>
					</div>
				</div>
				<div class="row formSpace">
					
				</div>
				<!-- {{ laravue-insert:field }} -->
            <br>
            <router-link :to="goBack">
              <p-button type="info" round>voltar</p-button>
            </router-link>
            <p-button style="margin-left:8px" v-if="relatorio" type="danger" round @click="getReport('pdf')">PDF</p-button>
            <p-button style="margin-left:8px" v-if="relatorio" type="success" round @click="getReport('xlsx')">XLSX</p-button>
            <p-button style="margin-left:8px" v-else type="success" round nativeType="submit">{{ labelSubmit }}</p-button>
          </form>
        </ValidationObserver>
      </card>
    </div>
  </div>
</template>

<script>
import laravueNotify from "@/components/ProjetoBase/Views/Components/notifications/laravueNotifications"
import PulseLoader from "vue-spinner/src/PulseLoader"
import { DatePicker } from "element-ui"

export default {
  components: { PulseLoader, [DatePicker.name]: DatePicker },
  props: {
    titulo: String,
    edicao: Boolean,
    relatorio: Boolean,
    rotaVoltar: String,
    rota: String
  },
  data() {
    return {
      backRoute: '',
      loadingMessage: "",
      loading: false,
      selects: { 
		alunos: [],
        // {{ laravue-insert:dataSelects }}
      },
      model: {
        aluno_id: '',
		data: '',
		costas: '',
		triceps: '',
		biceps: '',
		abdomen_lateral: '',
		abdomen_frontal: '',
		coxa: '',
		perna: '',
        // {{ laravue-insert:dataModel }}
      }
    }
  },
  mounted() {
    this.backRoute = this.$route.query.page ? `${this.rotaVoltar}?page=${this.$route.query.page}` : this.rotaVoltar
  },
  created() {
    this.loadSelects()
  },
  computed: {
    labelSubmit() {
      return this.edicao ? `Alterar` : `Cadastrar`
    },
    goBack() {
      return `/paginas/${this.backRoute}`
    },
    url() {
      return this.edicao
        ? `${this.rota}/${this.$route.params.modelId}`
        : this.rota
    },
    // {{ laravue-insert:computed }}
  },
  methods: {
    setLoading(status, message = "") {
      if(this.loading != status){
        this.loading = status
      }
      this.loadingMessage = message
    },
    getReportFilter() {
      return `?model_filter=${JSON.stringify(this.model)}`
    },
    getReport(modelo) {
      this.setLoading(true, "Gerando Arquivo")
      this.$http
        .get(`reports/${this.rota}/${modelo}${this.getReportFilter()}`, {responseType: 'arraybuffer'})
        .then(response => {
          this.setLoading(false)
          this.$getReport(response, modelo)
        })
        .catch(e => {
          this.setLoading(false, "Não foi possível gerar arquivo")
          laravueNotify.failure(this, e)
        })
    },
    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        let message = this.edicao ? "Alterando" : "Inserindo"
        this.setLoading(true, message)
        this.$http({
          method: this.edicao ? "put" : "post",
          url: this.url,
          data: { 
			aluno_id: parseInt(this.model.aluno_id),
			data: this.model.data,
			costas: this.$formatNumberToDatabase(this.model.costas),
			triceps: this.$formatNumberToDatabase(this.model.triceps),
			biceps: this.$formatNumberToDatabase(this.model.biceps),
			abdomen_lateral: this.$formatNumberToDatabase(this.model.abdomen_lateral),
			abdomen_frontal: this.$formatNumberToDatabase(this.model.abdomen_frontal),
			coxa: this.$formatNumberToDatabase(this.model.coxa),
			perna: this.$formatNumberToDatabase(this.model.perna), 
            // {{ laravue-insert:submit }}
          }
        })
          .then( () => {
            if (!this.edicao) {
              this.model = {
                aluno_id: '',
				data: '',
				costas: '',
				triceps: '',
				biceps: '',
				abdomen_lateral: '',
				abdomen_frontal: '',
				coxa: '',
				perna: '',
              }
              this.$refs.form.reset()
            }
            this.setLoading(false)
            if (this.edicao) {
              laravueNotify.success(this, "edit")
            } else {
              laravueNotify.success(this)
            }
          })
          .catch(e => {
            this.setLoading(false)
            laravueNotify.failure(this, e)
          })
      })
    },
    loadModel(){
      // {{ laravue-insert:loadModelMethod }}
      if (this.edicao) {
        this.setLoading(true, "Dobra Cutânea")
        this.$http
          .get(this.url)
          .then(response => {
            this.setLoading(false)
            this.model = response.data.data
			console.log('model: ', this.model)
            // {{ laravue-insert:loadModelResponse }} 
          })
          .catch(e => {
            laravueNotify.failure(this, e)
          })
      } else {
        this.setLoading(false)
      }
    },
    loadSelects() {
			this.setLoading(true, "carregando")
			this.loadAlunos() // cascade calls
		},
		loadAlunos() {
			this.setLoading(true, "Aluno")
			this.$http
				.get(`alunos?per_page=-1&not_null=id&order_by=id`)
				.then(response => {
					this.setLoading(false)
					this.selects.alunos = response.data.data.data
					this.loadModel()
				})
				.catch(e => {
					this.setLoading(false)
					laravueNotify.failure(this, e)
				})
		}, 
    // {{ laravue-insert:methods }}
  }
}
</script>

<style scoped>
.formSpace {
  margin-bottom: 8px;
}
</style>
