<template>
  <div class="row">
    <div class="col-sm-12">
      <card>
       <div class="row">
          <div class="col-sm-9">
              <h4 slot="header" class="card-title">{{ titulo }}</h4>
          </div>
          <div class="col-sm-2" style="text-align:right">
            <small>{{ loadingMessage }} </small>
          </div>
          <div class="col-sm-1">
               <pulse-loader :loading="loading" color="black" size="5px"></pulse-loader>
          </div>
        </div>
        <ValidationObserver ref="form">
            <form @submit.prevent="submit">
              <div class="row formSpace">
                <div class="col-sm-6">
                  <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
                     <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Tipo</div>
                      <el-select
                        filterable
                        class="baseSelect"
                        label="Grupo"
                        size="large"
                        :placeholder="relatorio ? 'Não filtrar' : 'Tipo'"
                        v-model="model.tipo"
                      >
                        <el-option v-if="relatorio" label="Não filtrar" value=""></el-option>
                        <el-option v-if="relatorio" label="Debug" value="DEBUG"></el-option>
                        <el-option v-if="relatorio" label="Informação" value="INFO"></el-option>
                        <el-option v-if="relatorio" label="Erro" value="ERRO"></el-option>
                        <el-option v-if="relatorio" label="Aviso" value="AVISO"></el-option>
                        <el-option v-if="relatorio" label="Crítico" value="CRITICO"></el-option>
                        <el-option v-if="relatorio" label="Emergencial" value="EMERGENCIAL"></el-option>
                      </el-select>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider name="Resultado" rules="required" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Resultado</div>
                    <el-select
                      filterable
                      class="baseSelect"
                      label="Status"
                      size="large"
                      :placeholder="relatorio ? 'Não filtrar' :'Resultado'"
                      v-model="model.resultado"
                    >
                      <el-option v-if="relatorio" label="Não filtrar" value=""></el-option>
                      <el-option v-if="relatorio" label="Sucesso" value="SUCESSO"></el-option>
                      <el-option v-if="relatorio" label="Neutro" value="NEUTRO"></el-option>
                      <el-option v-if="relatorio" label="Falha" value="FALHA"></el-option>
                    </el-select>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row formSpace">
                <div class="col-sm-6">
                  <ValidationProvider name="Tarefa" rules="required" v-slot="{ errors }">
                    <fg-input 
                      :placeholder="relatorio ? 'Não filtrar' :'Digite Tarefa'" 
                      label="Mensagem" 
                      v-model="model.mensagem">
                    </fg-input>
                    <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider name="Data" rules="required" v-slot="{ errors }">
                    <div style="margin-bottom: 5px; color: #9A9A9A; font-size: .8571em;">Data</div>
                    <el-date-picker
                      v-model="model.created_at"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      :placeholder="relatorio ? 'Não filtrar' :'Selecione data'">
                    </el-date-picker>
                      <div class="text-danger" style="font-size: .8271em; margin-top: 4px;">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- {{ laravue-insert:field }} -->
              <br>
              <router-link :to="goBack">
                <p-button type="info" round>voltar</p-button>
              </router-link>
              <p-button style="margin-left:8px" v-if="relatorio" type="danger" round @click="getReport('pdf')">PDF</p-button>
              <p-button style="margin-left:8px" v-if="relatorio" type="success" round @click="getReport('xlsx')">XLSX</p-button>
            </form>
        </ValidationObserver>
      </card>
    </div>
  </div>
</template>

<script>
import laravueNotify from "@/components/ProjetoBase/Views/Components/notifications/laravueNotifications"
import PulseLoader from "vue-spinner/src/PulseLoader"
import { DatePicker } from "element-ui"

export default {
  components: { PulseLoader, [DatePicker.name]: DatePicker },
  props: {
    titulo: String,
    edicao: Boolean,
    relatorio: Boolean,
    rotaVoltar: String,
    rota: String
  },
  data() {
    return {
      loadingMessage: "",
      loading: false,
      selects: { 
        // {{ laravue-insert:dataSelects }}
      },
      model: {
        tipo: "",
        resultado: "",
        mensagem: "",
        created_at: "",
        // {{ laravue-insert:dataModel }}
      }
    };
  },
  computed: {
    labelSubmit() {
      return this.edicao ? `Alterar` : `Cadastrar`;
    },
    goBack() {
      return `/paginas/${this.rotaVoltar}`;
    },
    url() {
      return this.edicao
        ? `${this.rota}/${this.$route.params.modelId}`
        : this.rota;
    },
    isMilestone: {
      get: function() {
        return this.model.is_milestone >= 1 ? true : false;
      },
      set: function(newValue) {
        this.model.is_milestone = newValue ? 1 : 0;
      }
    },
    isRoadmap: {
      get: function() {
        return this.model.is_roadmap >= 1 ? true : false;
      },
      set: function(newValue) {
        this.model.is_roadmap = newValue ? 1 : 0;
      }
    },
    // {{ laravue-insert:computed }}
  },
  methods: {
    setLoading(status, message) {
      if(this.loading != status){
        this.loading = status
      }
      this.loadingMessage = message
    },
    getReportFilter() {
      return `?model_filter=${JSON.stringify(this.model)}
              &orderby=id:DESC`
    },
    getReport(modelo) {
      this.$http
        .get(`reports/${this.rota}/${modelo}${this.getReportFilter()}`, {responseType: 'arraybuffer'})
        .then(response => {
          this.$getReport(response, modelo);
        })
        .catch(e => {
          laravueNotify.failure(this, e)
        })
    }
  }
}
</script>

<style >
.selectCenter {
  margin-top: 1.4rem;
}
.el-select {
  display: inline;
}
.baseSelect .el-input input {
  border: 1px solid #ddd !important;
  color: #66615b;
}
.formSpace {
  margin-bottom: 8px;
}
</style>
