import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import messages from './modules/messages/main'
import models from './modules/models/main'
import uploads from './modules/uploads/main'

Vue.use(Vuex)

export default new Vuex.Store({
    state,
    mutations,
    actions,
    modules: {
        messages,
        models,
        uploads
    }
})