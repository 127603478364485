var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.titulo))])]),_c('div',{staticClass:"col-sm-2",staticStyle:{"text-align":"right"}},[_c('small',[_vm._v(_vm._s(_vm.loadingMessage)+" ")])]),_c('div',{staticClass:"col-sm-1"},[_c('pulse-loader',{attrs:{"loading":_vm.loading,"color":"black","size":"5px"}})],1)]),_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Grupo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Grupo")]),_c('el-select',{staticClass:"baseSelect",staticStyle:{"width":"100%"},attrs:{"filterable":"","label":"Grupo","size":"large","placeholder":_vm.relatorio ? 'Não filtrar' : 'Grupo'},model:{value:(_vm.model.task_group_id),callback:function ($$v) {_vm.$set(_vm.model, "task_group_id", $$v)},expression:"model.task_group_id"}},[(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não filtrar","value":""}}):_vm._e(),_vm._l((_vm.selects.taskgroups),function(item){return _c('el-option',{key:item.id,staticClass:"select-danger",staticStyle:{"width":"100%"},attrs:{"value":item.id,"label":item.name}})})],2),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Status")]),_c('el-select',{staticClass:"baseSelect",staticStyle:{"width":"100%"},attrs:{"filterable":"","label":"Status","size":"large","placeholder":_vm.relatorio ? 'Não filtrar' :'Status'},model:{value:(_vm.model.task_status_id),callback:function ($$v) {_vm.$set(_vm.model, "task_status_id", $$v)},expression:"model.task_status_id"}},[(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não filtrar","value":""}}):_vm._e(),_vm._l((_vm.selects.taskstatuses),function(item){return _c('el-option',{key:item.id,staticClass:"select-danger",attrs:{"value":item.id,"label":item.name}})})],2),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Módulo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Módulo")]),_c('el-select',{staticClass:"baseSelect",staticStyle:{"width":"100%"},attrs:{"filterable":"","label":"Módulo","size":"large","placeholder":_vm.relatorio ? 'Não filtrar' :'Projeto'},model:{value:(_vm.model.project_module_id),callback:function ($$v) {_vm.$set(_vm.model, "project_module_id", $$v)},expression:"model.project_module_id"}},[(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não filtrar","value":""}}):_vm._e(),_vm._l((_vm.selects.projectmodules),function(item){return _c('el-option',{key:item.id,staticClass:"select-danger",attrs:{"value":item.id,"label":item.name}})})],2),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Usuário","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Usuário")]),_c('el-select',{staticClass:"baseSelect",staticStyle:{"width":"100%"},attrs:{"filterable":"","label":"Usuário","size":"large","placeholder":_vm.relatorio ? 'Não filtrar' :'Usuário'},model:{value:(_vm.model.user_id),callback:function ($$v) {_vm.$set(_vm.model, "user_id", $$v)},expression:"model.user_id"}},[(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não filtrar","value":""}}):_vm._e(),_vm._l((_vm.selects.users),function(item){return _c('el-option',{key:item.id,staticClass:"select-danger",attrs:{"value":item.id,"label":item.name}})})],2),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-12"},[_c('ValidationProvider',{attrs:{"name":"Tarefa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Tarefa',"label":"Tarefa"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Início","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Início")]),_c('el-date-picker',{attrs:{"type":"date","format":"dd/MM/yyyy","value-format":"yyyy-MM-dd","placeholder":_vm.relatorio ? 'Não filtrar' :'Selecione data'},model:{value:(_vm.model.started_at),callback:function ($$v) {_vm.$set(_vm.model, "started_at", $$v)},expression:"model.started_at"}}),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Fim","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Fim")]),_c('el-date-picker',{attrs:{"type":"date","format":"dd/MM/yyyy","value-format":"yyyy-MM-dd","placeholder":_vm.relatorio ? 'Não filtrar' :'Selecione data'},model:{value:(_vm.model.ended_at),callback:function ($$v) {_vm.$set(_vm.model, "ended_at", $$v)},expression:"model.ended_at"}}),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.relatorio)?_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Marco Histórico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Marco Histórico")]),_c('el-select',{model:{value:(_vm.model.is_milestone),callback:function ($$v) {_vm.$set(_vm.model, "is_milestone", $$v)},expression:"model.is_milestone"}},[(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não filtrar","value":""}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Sim","value":"true"}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não","value":"false"}}):_vm._e()],1),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3529583129)})],1):_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Marco Histórico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".9971em"}},[_vm._v(" ⠀⠀")]),_c('p-checkbox',{model:{value:(_vm.isMilestone),callback:function ($$v) {_vm.isMilestone=$$v},expression:"isMilestone"}},[_vm._v("Marco Histórico")]),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.relatorio)?_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Roteiro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Roteiro")]),_c('el-select',{model:{value:(_vm.model.is_roadmap),callback:function ($$v) {_vm.$set(_vm.model, "is_roadmap", $$v)},expression:"model.is_roadmap"}},[(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não filtrar","value":""}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Sim","value":"true"}}):_vm._e(),(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não","value":"false"}}):_vm._e()],1),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,false,649892411)})],1):_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"Roteiro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".9971em"}},[_vm._v(" ⠀⠀")]),_c('p-checkbox',{model:{value:(_vm.isRoadmap),callback:function ($$v) {_vm.isRoadmap=$$v},expression:"isRoadmap"}},[_vm._v("Roteiro")]),_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('br'),_c('router-link',{attrs:{"to":_vm.goBack}},[_c('p-button',{attrs:{"type":"info","round":""}},[_vm._v("voltar")])],1),(_vm.relatorio)?_c('p-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"success","round":""},on:{"click":function($event){return _vm.getReport('xlsx')}}},[_vm._v("XLS")]):_vm._e(),(_vm.relatorio)?_c('p-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"danger","round":""},on:{"click":function($event){return _vm.getReportDebug('pdf')}}},[_vm._v("PDF")]):_c('p-button',{staticStyle:{"margin-left":"8px"},attrs:{"nativeType":"submit","type":"success","round":""}},[_vm._v(_vm._s(_vm.labelSubmit))])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }