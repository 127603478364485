<template>
  <div>
      <h4>Roteiro de Evolução do Sistema</h4>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <time-line type="simple">
          <time-line-item
            class="timeline-inverted"
            badgeType="success"
            badgeIcon="nc-icon nc-check-2"
            v-for="(version, index) in versions" :key="index">
            <badge slot="header" type="danger"><strong>{{ version.name }}</strong></badge>
            <p slot="content">
                <ul v-if="version.tasks.length > 0">
                    <li v-for="(task, index) in version.tasks" :key="index">
                       <small><b>{{ task.type }}</b>: {{ task.name }}</small>
                    </li>
                </ul>
            </p>
          </time-line-item>
        </time-line>
      </div>
      <div class="col-lg-6 col-md-12 text-center"></div>
    </div>
  </div>
</template>

<script>
  import { TimeLine, TimeLineItem, Badge } from 'src/components/UIComponents'
  import laravueNotify from "@/components/ProjetoBase/Views/Components/notifications/laravueNotifications";

  export default {
    components: {
      TimeLine,
      TimeLineItem,
      Badge,
    },
    data() {
      return {
        versions: []
      }
    },
    mounted() {
        this.$http.get('roadmaps').then( response => {
            this.versions = response.data.data
        }).catch(e => {
            this.loading = false;
            laravueNotify.failure(this, e);
        })
    }
  }
</script>
