<template>
  <div v-if="model">
    <div class="row">
		<div class="col-sm-12">
			<p>
				<b>Nome</b>
				<br/>
				{{ model.nome }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p>
				<b>Ativo</b>
				<br/>
				{{ model.ativo }}
			</p>
		</div>
	</div>
    <!-- {{ laravue-insert:field }} -->
  </div>
</template>

<script>
export default {
  name: "Show",
  props: {
    model: undefined
  }
}
</script>