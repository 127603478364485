<template>
  <div>
    <laravue-datatable
      rota="roles"
      :colunas = "colunas"
      :ordenacao = "ordenacao">
    </laravue-datatable>
  </div>
</template>

<script>
import LaravueDatatable from "@/components/ProjetoBase/Views/Components/LaravueDataTable"

export default {
  components: { LaravueDatatable },
  data() {
    return {
      colunas: [
        {
          prop: "name",
          label: "Perfil de acesso",
          minWidth: 250,
        },
      ],
      ordenacao:[
        {
          prop: "name",
          order: "ASC",
          default: true
        }
      ]
    }
  }
}
</script>
