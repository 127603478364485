var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.titulo))])]),_c('div',{staticClass:"col-sm-2",staticStyle:{"text-align":"right"}},[_c('small',[_vm._v(_vm._s(_vm.loadingMessage)+" ")])]),_c('div',{staticClass:"col-sm-1"},[_c('pulse-loader',{attrs:{"loading":_vm.loading,"color":"black","size":"5px"}})],1)]),_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-4"},[_c('ValidationProvider',{attrs:{"name":"Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.relatorio)?_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Data")]):_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Período da Data")]),(!_vm.relatorio)?_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","format":"dd/MM/yyyy","value-format":"yyyy-MM-dd","placeholder":_vm.relatorio ? 'Não filtrar' : 'Selecione data'},model:{value:(_vm.model.data),callback:function ($$v) {_vm.$set(_vm.model, "data", $$v)},expression:"model.data"}}):_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"daterange","range-separator":" à ","format":"dd/MM/yyyy","value-format":"yyyy-MM-dd","start-placeholder":"Data inicial","end-placeholder":"Data final"},model:{value:(_vm.model.data_date_range),callback:function ($$v) {_vm.$set(_vm.model, "data_date_range", $$v)},expression:"model.data_date_range"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-8"},[_c('ValidationProvider',{attrs:{"name":"Aluno","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"margin-bottom":"5px","color":"#9A9A9A","font-size":".8571em"}},[_vm._v("Aluno")]),_c('el-select',{staticClass:"baseSelect",staticStyle:{"width":"100%"},attrs:{"filterable":"","size":"large","placeholder":_vm.relatorio ? 'Não filtrar' : 'Aluno'},model:{value:(_vm.model.aluno_id),callback:function ($$v) {_vm.$set(_vm.model, "aluno_id", $$v)},expression:"model.aluno_id"}},[(_vm.relatorio)?_c('el-option',{attrs:{"label":"Não filtrar","value":""}}):_vm._e(),_vm._l((_vm.selects.alunos),function(item){return _c('el-option',{key:item.id,staticClass:"select-danger",staticStyle:{"width":"100%"},attrs:{"value":item.id,"label":item.user.name}})})],2),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-4"},[_c('ValidationProvider',{attrs:{"name":"Costas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Costas',"label":"Costas"},model:{value:(_vm.model.costas),callback:function ($$v) {_vm.$set(_vm.model, "costas", $$v)},expression:"model.costas"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-4"},[_c('ValidationProvider',{attrs:{"name":"Tríceps","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite ',"label":"Tríceps"},model:{value:(_vm.model.triceps),callback:function ($$v) {_vm.$set(_vm.model, "triceps", $$v)},expression:"model.triceps"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-4"},[_c('ValidationProvider',{attrs:{"name":"Bíceps","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Bíceps',"label":"Bíceps"},model:{value:(_vm.model.biceps),callback:function ($$v) {_vm.$set(_vm.model, "biceps", $$v)},expression:"model.biceps"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Abdômen Lateral","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Abdômen Lateral',"label":"Abdômen Lateral"},model:{value:(_vm.model.abdomen_lateral),callback:function ($$v) {_vm.$set(_vm.model, "abdomen_lateral", $$v)},expression:"model.abdomen_lateral"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Abdômen Frontal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Abdômen Frontal',"label":"Abdômen Frontal"},model:{value:(_vm.model.abdomen_frontal),callback:function ($$v) {_vm.$set(_vm.model, "abdomen_frontal", $$v)},expression:"model.abdomen_frontal"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"row formSpace"},[_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Coxa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Coxa',"label":"Coxa"},model:{value:(_vm.model.coxa),callback:function ($$v) {_vm.$set(_vm.model, "coxa", $$v)},expression:"model.coxa"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"Perna","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"placeholder":_vm.relatorio ? 'Não filtrar' :'Digite Perna',"label":"Perna"},model:{value:(_vm.model.perna),callback:function ($$v) {_vm.$set(_vm.model, "perna", $$v)},expression:"model.perna"}}),(!_vm.relatorio)?_c('div',{staticClass:"text-danger",staticStyle:{"font-size":".8271em","margin-top":"4px"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"row formSpace"}),_c('br'),_c('router-link',{attrs:{"to":_vm.goBack}},[_c('p-button',{attrs:{"type":"info","round":""}},[_vm._v("voltar")])],1),(_vm.relatorio)?_c('p-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"danger","round":""},on:{"click":function($event){return _vm.getReport('pdf')}}},[_vm._v("PDF")]):_vm._e(),(_vm.relatorio)?_c('p-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"success","round":""},on:{"click":function($event){return _vm.getReport('xlsx')}}},[_vm._v("XLSX")]):_c('p-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"success","round":"","nativeType":"submit"}},[_vm._v(_vm._s(_vm.labelSubmit))])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }