<template>
  <div>
    <role-datatable
      rota="roles"
      :colunas = "colunas"
      :ordenacao = "ordenacao">
    </role-datatable>
  </div>
</template>

<script>
import RoleDatatable from "./components/RoleDataTable";

export default {
  components: { RoleDatatable },
  data() {
    return {
      colunas: [
        {
          prop: "name",
          label: "Nome",
          minWidth: 290,
        },
      ],
      ordenacao:[
        {
          prop: "name",
          order: "ASC",
          default: true
        }
      ]
    }
  }
}
</script>
